.icon-button {
    appearance: none;
    background: transparent;
    color: $vnf-bleu-a2;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    text-decoration: none;

    &--bleu-a3 {
        color: $vnf-bleu-a3;
    }

    &--bleu-a4 {
        color: $vnf-bleu-a4;
    }

    i {
        font-size: 36px;
    }

    &-hover {
        &--bleu-a2:hover {
            color: $vnf-bleu-a2;
        }

        &--bleu-a3:hover {
            color: $vnf-bleu-a3;
        }
    }

    span:not(.sr-only) {
        font-family: "Roboto Condensed", sans-serif;
        font-size: 14px;
        width: 70px;
        text-align: left;
        word-break: initial;
    }

    &:disabled i {
        color: $vnf-gris-b2;
        cursor: default;
    }
}