.primary-form {
    padding: 30px 15px;
    background-color: $white;
    color: $gray1000;
    width: 600px;
    max-width: 100%;
    margin: auto;

    @media (min-width: 768px) {
        padding: 50px 60px;
    }

    &__title {
        font-size: 21px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 10px;
        color: $vnf-bleu-a4;


        i {
            font-size: 30px;
            position: relative;
            top: 3px;
        }

        @media (min-width: 768px) {
            font-size: 30px;
        }
    }

    &__description {
        width: 75%;
        margin: auto;
        text-align: center;
        font-size: 16px;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 14px 0;

        &--right {
            justify-content: flex-end;
        }

        &--center {
            justify-content: center;
        }
    }

    &__text {
        font-size: 16px;
        color: $vnf-gris-b4;
        margin: 14px 0 6px;
    }

    &__required-fields {
        font-size: 12px;
        color: $vnf-gris-b3;
        margin: 0;
    }

    &__multiple-checkbox-wrapper {
        padding: 0 20px;

        .input-wrapper {
            margin: 0;
        }
    }
}

.secondary-form {
    padding: 25px;
    background-color: $vnf-bleu-a4;
    color: $white;
    width: 368px;
    max-width: 100%;

    &__title {
        text-align: center;
        padding-bottom: 10px;
        color: inherit;
        font-weight: 400;
    }

    .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;

        a {
            color: $white;
            font-size: 14px;
            margin-top: 6px;
            text-decoration: none;
            outline: none;

            &:hover,
            &:focus {
                color: #ff9900;
            }
        }
    }
}

.form-divider {
    margin: 10px 0;
    border: none;
    height: 1px;
    background-color: $vnf-gris-b1;

    &--big {
        margin: 10px 0 25px;
        height: 4px;
        background-color: $vnf-bleu-a2;
    }
}

.input {
    // PRIMARY
    &--primary {
        position: relative;
        display: flex;
        background: transparent;
        height: 56px;
        border: 1px solid $vnf-gris-b2;
        border-radius: 4px;
        color: $vnf-gris-b3;

        input {
            width: 100%;
            height: 100%;
            padding:0 16px;
            border: none;
            background-color: transparent;
            // color: inherit;
            color: $vnf-bleu-a4;
            font-size: 16px;
            border-radius: 4px;
    
            &:focus {
                outline: none;
            }

            &:disabled {
                background: $vnf-gris-b1;
            }
        }

        .label {
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%) translateX(-4px);
            pointer-events: none;
            font-size: 16px;
            font-weight: 400px;
            transform-origin: top left;
            will-change: transform;
            transition: $transition-bezier;
            padding: 4px;
        }

        &.focus,
        &.dirty {
            .label {
                transform: translateY(-38px) translateX(-4px) scale(0.75);
                background: $white;
            }
    
            &:after {
                background: $vnf-vert-b1;
                height: 2px;
            }
        }
    
        &.error {
            border-color: $alert-danger;

            .label {
                color: $alert-danger;
            }
    
            &:after {
                background: $alert-danger;
                height: 2px;
            }
        }
    }

    // INPUT SECONDARY
    &--secondary {
        position: relative;
        display: flex;
        background: transparent;
        height: 46px;

        &:after {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            top: 100%;
            height: 1px;
            transform: translateY(-50%);
            background: #7f96c7;
        }
    
        input,
        input:-webkit-autofill {
            height: 100%;
            width: 100%;
            padding: 0 16px;
            border: none;
            background-color: transparent;
            color: inherit;
            font-size: 16px;
    
            &:focus {
                outline: none;
            }
        }
    
        .label {
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
            font-size: 18px;
            font-weight: 400px;
            transform-origin: top left;
            will-change: transform;
            transition: $transition-bezier;
            padding: 4px;
        }
    
        &.focus,
        &.dirty {
            .label {
                transform: translateY(-34px) translateX(-4px) scale(0.75);
            }
    
            &:after {
                background: $vnf-vert-b1;
                height: 2px;
            }
        }

        input:-webkit-autofill ~ .label {
            transform: translateY(-34px) translateX(-4px) scale(0.75);
        }
    
        &.error {
            .label {
                color: $white;
            }
    
            &:after {
                background: $white;
                height: 2px;
            }
        }
    }

    &-wrapper {
        width: 100%;
        margin: 14px 0;
        position: relative;
    }

    &__show-password {
        appearance: none;
        background: transparent;
        border: none;
        padding: 0;
        font-size: 22px;
        line-height: 1;
        color: inherit;
        position: absolute;
        right: 0;
        top: 5px;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .6;
        outline: none;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }

    &--button {
        input {
            height: auto;
            min-height: 100%;
            white-space: pre-wrap;
            line-height: 1.2;
            font-size: 14px;
            cursor: pointer;
        }
    }

    &--text-area {
        textarea {
            color: $vnf-bleu-a4;
        }
    }
}

.input-phone {
    color: #767676;

    .special-label {
        position: absolute;
        display: block;
        left: 16px;
        z-index: 10;
        color: inherit;
    }

    .form-control {
        height: 54px;
        width: 100%;
        // color: inherit;
        color: $vnf-bleu-a4;
        font-size: 16px;
    }

    .flag-dropdown {
        background: inherit !important;
        width: 100%;
        pointer-events: none;

        .selected-flag {
            background-color: inherit !important;
            pointer-events: all;

            &:focus,
            &:hover,
            &.open {
                background: #eee !important;
            }
        }

        .country-list {
            width: 100%;
            pointer-events: all;
        }
    }
}

// TODO => SECONDARY VS PRIMARY
.helper {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
    line-height: 1.2;
    margin: 5px 0;

    &--erreur,
    &--succes {
        position: absolute;
        top: 100%;
        margin-top: -4px;
    }

    &--primary {
        color: $alert-info;
    
        &--erreur {
            color: $alert-danger;
        }
    
        &--success {
            color: $alert-success;
        }
    }

    &--secondary {
        color: $white;

        &--erreur,
        &--succes {
            color: $white;
        }
    }

    &--absolute {
        position: absolute;
    }

    i {
        font-size: 16px;
        margin-right: 5px;
    }
}


.checkbox-container {
    .checkbox {
        height: auto;
    }

    .input-wrapper:not(:first-child) {
        margin-top: -14px;
    }
}

.checkbox {
    width: 100%;
    display: flex;
    align-items: baseline;
    height: 57px;

    &.disabled {
        opacity: 0.5;
    }

    label {
        margin: 0 4px 0 -10px;
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        transition: $transition-bezier;

        &.disabled {
            cursor: not-allowed;
        }

        &:not(.disabled) {
            &:hover,
            &:focus-within {
                background: #f7f8fc;
            }
        }
    }

    input {
        appearance: none;
        width: 18px;
        height: 18px;
        border: 2px solid $gray1000;
        background-color: transparent;
        border-radius: 2px;
        outline: none;
        cursor: inherit;

        &:checked {
            border-color: $vnf-bleu-a4;
            background: url('/assets/img/icons/checkmark-white.svg') no-repeat center $vnf-bleu-a4;
            background-size: contain;
        }
    }

    .label {
        margin: 0;
        font-size: 14px;
        position: relative;
        top: -4px;
    }

    &--secondary {
        input {
            border: 2px solid $white;

            &:checked {
                border-color: $white;
                background: url('/assets/img/icons/checkmark.svg') no-repeat center $white;
                background-size: contain;
            }
        }

        label {
            &.checked {
                background: $vnf-bleu-a3-focus;
            }
    
            &:not(.disabled) {
                &:hover,
                &:focus-within {
                    background: $vnf-bleu-a3-hover;
                }
            }
        }
    }
}

.select {
    position: relative;
    height: 56px;
    color: #767676;
    border: 1px solid #c2c2c2;
    border-radius: 5px;

    select {
        width: 100%;
        height: 100%;
        padding: 0 16px;
        appearance: none;
        // color: inherit;
        color: $vnf-bleu-a4;
        font-size: 16px;
        background-color: transparent;
        cursor: pointer;

        option {
            width: 100%;
        }

    }

    label {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%) translateX(-4px);
        pointer-events: none;
        font-size: 16px;
        font-weight: 400px;
        transform-origin: top left;
        will-change: transform;
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
        background: #fff;
        padding: 4px;
    }

    i {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &.focus,
    &.dirty {
        label {
            transform: translateY(-38px) translateX(-4px) scale(0.75);
        }
    }
}

.submit {
    appearance: none;
    padding: 7px 20px;
    font-size: 16px;
    font-weight: 500;
    background: $white;
    color: $vnf-bleu-a4;
    box-shadow: 4px 4px 0 0 $vnf-vert-b1;
    outline: none;
    cursor: default;
    opacity: 0.5;

    &:not([disabled]) {
        opacity: 1;
        cursor: pointer;

        &:hover {
            background: $vnf-bleu-a4-hover;
            color: $white;
            box-shadow: 4px 4px 0 0 $vnf-vert-b1-hover;
        }
    
        &:focus {
            background: $vnf-bleu-a4-focus;
            color: $white;
            box-shadow: 4px 4px 0 0 $vnf-vert-b1-focus;
        }
    }

    &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 28px;
    }
}

.multi-checkbox {
    &__label {
        font-size: 16px;
        font-weight: 400px;
        color: $vnf-gris-b3;
        margin-bottom: 10px;
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        gap: 0 20px;
    }

    .checkbox {
        height: unset;

        &--checkall {
            margin-top: 20px;
        }
    }
}

.input-file {
    label {
        width: 100%;
        min-height: 100px;
        border: 1px solid $vnf-gris-b2;
        border-radius: 4px;
        display: block;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        transition: $transition-bezier;
        padding: 4px;

        &:hover,
        &:focus-within,
        &.active {
            border-color: $vnf-bleu-a4;
            outline: 1px solid $vnf-bleu-a4;
        }

        i {
            font-size: 50px;
            color: $vnf-bleu-a4;
        }
    }

    input {
        display: none;
    }

    &__label {
        display: block;
        font-size: 16px;
        color: $vnf-gris-b3;
        margin-bottom: 15px;
    }

    &__instruction {
        color: $vnf-bleu-a3;
        text-align: center;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $vnf-bleu-a4;
        line-height: 1;
        margin-top: 10px;

        .icon-button {
            font-size: 14px;
            font-family: 'Roboto Condensed';
            align-items: center;

            i {
                font-size: 40px;
            }

            span:not(.sr-only) {
                width: auto;
            }
        }
    }
}

.form-switch {
    font-size: 14px;

    .Mui-checked {
        & ~ .MuiSwitch-track {
            opacity: 1 !important;
        }
    }

    .MuiSwitch-track {
        background: $vnf-bleu-a1 !important;
    }

    .MuiButtonBase-root {
        background: transparent !important;
        color: transparent !important;
    }

    &--primary {
        color: $vnf-bleu-a4;

        .MuiSwitch-thumb {
            background: $vnf-bleu-a4;
        }
    }

    &--secondary {
        color: $white;
        
        .MuiSwitch-thumb {
            background: #fff;
        }
    }
}

.form-permission {
    label {
        display: block;
        font-size: 16px;
        color: #767676;
        width: 140px;
        margin-bottom: 6px;
    }

    input {
        width: 100%;
        white-space: pre-wrap;
        height: 56px;
        flex: 1;
    }

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 56px;

        label {
            margin-bottom: 0;
        }
    }
}