.patch-note {
    background: #fff;
    box-shadow: $shadow-sm;
    padding: 15px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
        padding: 30px;
    }

    &__title {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    ul {
        padding-left: 0;
    }

    &__badge {
        margin-bottom: 8px;
        color: $white;
        background-color: $vnf-bleu-a2;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 1;
    }
}