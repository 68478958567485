.pagination {
    // margin-top: -40px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    &__link {
        width: 32px;
        height: 32px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        border-radius: 50%;
        font-size: 18px;
        line-height: 1;
        color: $vnf-bleu-a4;
        margin: 0 2px;
        outline: none;
        text-decoration: none;
        transition: $transition-bezier;

        i {
            font-size: 1.2em;
        }

        &[disabled] {
            opacity: 0.5;
        }

        &:not([disabled]) {
            cursor: pointer;

            &:hover {
                color: $white;
                background: $vnf-bleu-a2-hover;
            }
    
            &:focus {
                color: $white;
                background: $vnf-bleu-a2-focus;
            }

            &.active {
                color: $white;
                background: $vnf-bleu-a4;
            }
        }

        @media (min-width: 768px) {
            width: 38px;
            height: 38px;
            font-size: 20px;
        }
    }
}