html,
body,
#root {
    height: 100%;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.sr-only-desktop {
    @media (min-width: 768px) {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        border: 0;
    }
}

.visible-mobile {
    @media (min-width: 768px) {
        display: none;
    }
}

.visible-desktop {
    @media (max-width: 767px) {
        display: none;
    }
}

*,
:before,
:after {
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;

    &.unstyled {
        list-style-type: disc; 
        list-style-position: inside; 
        margin: 1em 0;
        padding-left: 40px;

        li {
            margin-bottom: 0.5rem;
        }
    }
}

ol li {
    margin-bottom: 0.5rem;
}

body {
    font-family: 'Roboto';
    font-size: 16px;
    color: $gray1000;
    background: $vnf-gris-b1;
}

h1,
.h1 {
    font-family: "Roboto", "Roboto-Light";
    color: $vnf-bleu-a4;
    font-size: 25px;
    font-weight: 300;

    @media (min-width: 768px) {
        font-size: 35px;
    }
}

h2,
.h2 {
    font-family: "Roboto", "Roboto-Medium";
    color: $vnf-bleu-a4;
    font-size: 21px;
    font-weight: 500;

    @media (min-width: 768px) {
        font-size: 30px;
    }
}

h3,
.h3 {
    font-family: "Roboto", "Roboto-Medium";
    color: $vnf-bleu-a4;
    font-size: 23px;
    font-weight: 500;
}

h4,
.h4 {
    font-family: "Roboto", "Roboto-Medium";
    color: $vnf-bleu-a4;
    font-size: 19px;
    font-weight: 500;
}

h5,
.h5 {
    font-family: "Roboto", "Roboto-Medium";
    color: $vnf-bleu-a4;
    font-size: 21px;
    font-weight: 400;
}

h6,
.h6 {
    font-family: "Roboto", "Roboto-Bold";
    color: $vnf-bleu-a4;
    font-size: 14px;
    font-weight: 700;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

pre {
    margin: 0 15px;
    display: block;
    background: #000;
    color: #fff;
    padding: 15px;
}

// FIX FOR CHROME AUTOFILL STYLING
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
  background: unset;
}

.loader_overlay {
    z-index: 999999;
}