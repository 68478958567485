.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3000;
    padding: 15px;
    display: flex;
    visibility: hidden;
    transition: 0.25s ease-in-out;
    opacity: 0;
    overflow-y: auto;

    @media (min-width: $screen-md-min) {
        padding: 25px;
    }

    &.enter,
    &.enter-done {
        visibility: visible;
        opacity: 1;
    }

    &.exit {
        opacity: 0;

        &-done {
            visibility: hidden;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.32;
        z-index: 1;
    }

    &__content {
        background: $white;
        width: 770px;
        max-width: 100%;
        padding: 24px 15px;
        position: relative;
        z-index: 2;
        margin: auto;

        &--lg {
            width: 988px;
        }

        @for $i from 1 through 10 {
            &--lg-#{$i} {
                @media (min-width: $screen-md-min) {
                    width: $connector-size-lg*$i+58px
                }
            }
        }

        @media (min-width: $screen-md-min) {
            padding: 34px 24px;
        }

        &__connector {
            @media (min-width: $screen-md-min) {
                overflow-x: auto;
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        appearance: none;
        background: transparent;
        border: none;
        font-size: 28px;
        color: $vnf-gris-b2;
        cursor: pointer;

        &:hover {
            color: $vnf-gris-b3;
        }
    }

    &__header {
        width: 420px;
        max-width: 100%;
        text-align: center;
        margin: auto;
    }

    &__title {
        font-family: "Roboto";
        font-size: 21px;
        line-height: 27px;
        font-weight: 500;
        color: $vnf-bleu-a4;
        margin-bottom: 10px;
        margin-top: 0;
    }

    &__description {
        text-align: center;
        color: $gray1000;
        margin-bottom: 10px;
        line-height: 1.4;
        margin-top: 0;

        &__connector {
            @media (min-width: $screen-md-min) {
                position: sticky;
                left: 0;
            }
        }
    }

    &__buttons {
        width: 420px;
        max-width: 100%;
        margin: auto;
        padding-top: 30px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;
        gap: 30px;

        &--right {
            width: unset;
            justify-content: flex-end;
        }

        &--center {
            width: unset;
            justify-content: center;
        }

        @media (max-width: 575px) {
            flex-direction: column;
            align-items: center;
        }

        &__connector {
            @media (min-width: $screen-md-min) {
                position: sticky;
                left: 0;
                margin: inherit;
            }
        }
    }

    &__body {
        &__connector {
            @media (min-width: $screen-md-min) {
                display: flex;
                flex-direction: column;
                overflow-x: auto;
            }
            scrollbar-color: #698ddb transparent;
        }
    }

    .primary-form {
        padding: 0;
    }
}