.password-helper {
    font-size: .8em;
    font-style: italic;
    
    p {
        font-size: 1em;
    }

    ul {
        list-style: disc;
        padding-left: 1em;

        li {
            &.valid {
                color: $alert-success;
            }
        }
    }
}