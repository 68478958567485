.filter-dropdown {
    &__btn {
        font-family: 'Robot', sans-serif;
        appearance: none;
        background: transparent;
        border: none;
        color: $white;
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        margin: auto;

        i {
            &:first-child {
                font-size: 58px;
            }

            &:last-child {
                font-size: 25px;
            }
        }

        @media (min-width: 992px) {
            position: absolute;
            bottom: 0;
            right: 15px;
        }
    }

    &__content {
        transition: $transition-bezier;
        overflow: hidden;
        
        &.open {
            overflow: visible;
        }
    }
}

.filter {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    label {
        font-size: 16px;
        width: 100%;
        // white-space: nowrap;
    }

    &__input-wrapper {
        width: 100%;
        flex-shrink: 0;
        position: relative;

        @media (min-width: 768px) {
            flex-shrink: 1;
        }
    }
    
    input,
    select {
        width: 100%;
        flex-shrink: 0;
        border: 1px solid $vnf-gris-b3;
        font-size: 16px;
        padding: 6px 10px;
        color: $vnf-gris-b3;
        background: $white;
    }

    &--date {
        @media (min-width: 768px) {
            align-items: flex-start;
    
            label {
                height: 37px;
                display: flex;
                align-items: center;
            }
        }

        .MuiTextField-root {
            width: 100%;
            flex-shrink: 0;
            border-color: $vnf-gris-b3;

            @media (min-width: 768px) {
                flex-shrink: 1;
            }
    
            input {
                flex: unset;
                padding: 6px 10px;
                cursor: pointer;
                border: none;
                font-size: 16px;
                height: 37px;
                box-sizing: border-box;
                color: $vnf-gris-b3;
            }

            &.error {
                // border-color: $alert-danger;
                // border-color: $white;
                .MuiInputBase-root {
                    &:not(:focus-within) {
                        outline: 1px solid $white;
                    }
                }
            }
        }

        .MuiInputBase-root {
            padding: 0;
            background: $white !important;
            border: 1px solid;
            border-color: inherit;
            border-radius: 0;

            &:before,
            &:after {
                content: none;
            }

            &:focus-within {
                outline: 2px solid #3daee9;
            }
        }

        .MuiIconButton-root {
            padding: 0;
        }

        .MuiFormHelperText-root {
            margin: 4px 0 0;
            // color: $alert-danger;
            line-height: 1.2;
            color: $white !important;
        }

        i {
            color: $vnf-bleu-a4;
            font-size: 40px;
        }
    }

    &--checkbox {
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;

        label {
            width: auto;
        }

        input {
            width: 20px;
            height: 20px;
            border: none;
        }
    }

    &__suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 10;
        width: 100%;
        list-style: none;
        border: 1px solid transparent;
        overflow: auto;
        max-height: 142px;
        box-shadow: $shadow-sm;
        background: $white;

        button {
            width: 100%;
            appearance: none;
            text-align: unset;
            height: 28px;
            padding: 0 10px;
            background: $white;
            outline: none;
            color: $gray1000;

            &:focus,
            &:hover {
                background: #eee;
                cursor: pointer;
            }
        }
    }

    &--small {
        label {
            width: auto;
        }
    }
}

.filter-elements {
    width: 100%;
    height: 29px;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 24px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: $vnf-bleu-a4;
    border: 2px solid $vnf-bleu-a4;
    border-radius: 4px;
    cursor: pointer;
    background: $white;
    margin-left: auto;

    @media (min-width: 768px) {
        width: 180px;
    }
}

.status-dropdown {
    position: relative;

    button {
        appearance: none;
        width: 36px;
        height: 36px;
        background: transparent;
        cursor: pointer;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__badge {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        &--waiting {
            background: $vnf-gris-b2;
        }

        &--allowed {
            background: $alert-success;
        }

        &--denied {
            background: $alert-danger;
        }
    }

    &__list {
        position: absolute;
        top: 100%;
        left: 50%;
        transform:translateX(-50%);
        background: #fff;
        z-index: 1;
        box-shadow: $shadow-sm;
        width: fit-content;
        box-sizing: content-box;

        button {
            width: 100%;
            appearance: none;
            padding: 4px 6px;
            background: $white;
            color: $vnf-bleu-a4;

            &:hover,
            &:focus {
                background: $vnf-gris-b1;
            }
        }
    }
}

.sortby {
    appearance: none;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    cursor: pointer;
    text-align: unset;

    i {
        font-size: 1.5em;
    }
}