.pdf-reader {
    position: relative;

    &__container {
        overflow: hidden;
        width: 100%;
    }

    &__actions {
        background: $vnf-gris-b1;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1;

        @media (min-width: 768px) {
            top: 60px;
        }
    }

    &__document {
        overflow: auto;
        display: flex;

        .react-pdf__Document {
            margin: auto;
        }
    }

    &__pages,
    &__zoom,
    &__download {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;

        button {
            appearance: none;
            background: $vnf-bleu-a2;
            color: $white;
            line-height: 1;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:disabled {
                opacity: .5;
            }

            &:not(:disabled) {
                cursor: pointer;

                &:hover {
                    background-color: $vnf-bleu-a2-hover;
                }

                &:focus {
                    background-color: $vnf-bleu-a2-focus;
                }
            }

            i {
                font-size: 30px;
            }
        }

        a {
            background: $vnf-bleu-a2;
            padding: 2px;
            color: $white;
            line-height: 1;
            text-decoration: none;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            i {
                font-size: 30px;
            }
    
            &:hover {
                background-color: $vnf-bleu-a2-hover;
            }
    
            &:focus {
                background-color: $vnf-bleu-a2-focus;
            }
        }
    }
}