.vnf-btn,
.vnf-btn:focus {
  font-family: roboto, arial, sans-serif;
  border-radius: 0;
  border: none;
  cursor: pointer;
  outline: none;
  margin-right: 5px;
  text-decoration: none;
  display: inline-block;
}

.vnf-btn.vnf-btn-m:not(.vnf-btn-tertiary) {
  font-size: 16px;
  font-weight: 400;
  padding: 7px 20px;
  letter-spacing: 1px;
}

.vnf-btn.vnf-btn-xl {
  font-size: 16px;
  font-weight: 400;
  padding: 26px 25px;
  letter-spacing: 1px;
  min-width: 185px;
  text-transform: uppercase;
}

.vnf-btn.vnf-btn-xxl {
  font-size: 31px;
  font-weight: 300;
  letter-spacing: 1px;
  padding: 19px 30px;
  min-width: 250px;

  span {
    display: block;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 1px;
  }
}

.vnf-btn.vnf-btn-multi-color,
.vnf-btn.vnf-btn-similar-color,
.vnf-btn.vnf-btn-contained {
  color: $blanc;
}

$vnf-btn-box-shadow-offset-x: 4px;
$vnf-btn-box-shadow-offset-y: 4px;
$vnf-btn-box-shadow-blur-radius: 0;
$vnf-btn-box-shadow-spread-radius: 0;

@mixin vnf-btn-box-shadow(
  $offset-x,
  $offset-y,
  $blur-radius,
  $spread-radius,
  $color
) {
  box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color;
}

.vnf-btn {
  &.vnf-btn-box-shadow-bleu-a1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-a1
    );
  }

  &.vnf-btn-box-shadow-bleu-a2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-a2
    );
  }

  &.vnf-btn-box-shadow-bleu-a3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-a3
    );
  }

  &.vnf-btn-box-shadow-bleu-a4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-a4
    );
  }

  &.vnf-btn-box-shadow-bleu-a5 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-a5
    );
  }

  &.vnf-btn-box-shadow-bleu-b1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-b1
    );
  }

  &.vnf-btn-box-shadow-bleu-b2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-b2
    );
  }

  &.vnf-btn-box-shadow-bleu-b3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-b3
    );
  }

  &.vnf-btn-box-shadow-bleu-b4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-bleu-b4
    );
  }

  &.vnf-btn-box-shadow-vert-a1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-a1
    );
  }

  &.vnf-btn-box-shadow-vert-a2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-a2
    );
  }

  &.vnf-btn-box-shadow-vert-a3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-a3
    );
  }

  &.vnf-btn-box-shadow-vert-a4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-a4
    );
  }

  &.vnf-btn-box-shadow-vert-b1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-b1
    );
  }

  &.vnf-btn-box-shadow-vert-b2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-b2
    );
  }

  &.vnf-btn-box-shadow-vert-b3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-b3
    );
  }

  &.vnf-btn-box-shadow-vert-b4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-b4
    );
  }

  &.vnf-btn-box-shadow-vert-c1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-c1
    );
  }

  &.vnf-btn-box-shadow-vert-c2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-c2
    );
  }

  &.vnf-btn-box-shadow-vert-c3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-c3
    );
  }

  &.vnf-btn-box-shadow-vert-c4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-vert-c4
    );
  }

  &.vnf-btn-box-shadow-orange-a1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-orange-a1
    );
  }

  &.vnf-btn-box-shadow-orange-a2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-orange-a2
    );
  }

  &.vnf-btn-box-shadow-orange-a3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-orange-a3
    );
  }

  &.vnf-btn-box-shadow-orange-a4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-orange-a4
    );
  }

  &.vnf-btn-box-shadow-violet-a1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-violet-a1
    );
  }

  &.vnf-btn-box-shadow-violet-a2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-violet-a2
    );
  }

  &.vnf-btn-box-shadow-violet-a3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-violet-a3
    );
  }

  &.vnf-btn-box-shadow-violet-a4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-violet-a4
    );
  }

  &.vnf-btn-box-shadow-gris-a1 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-gris-a1
    );
  }

  &.vnf-btn-box-shadow-gris-a2 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-gris-a2
    );
  }

  &.vnf-btn-box-shadow-gris-a3 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-gris-a3
    );
  }

  &.vnf-btn-box-shadow-gris-a4 {
    @include vnf-btn-box-shadow(
      $vnf-btn-box-shadow-offset-x,
      $vnf-btn-box-shadow-offset-y,
      $vnf-btn-box-shadow-blur-radius,
      $vnf-btn-box-shadow-spread-radius,
      $vnf-gris-a4
    );
  }
}

@mixin vnf-btn_declinaison-blanche($color, $hover, $focus) {
  color: $color;
  background-color: $blanc;
  border: 2px solid $blanc;

  &:hover {
    color: $blanc;
    border: 2px solid $hover;
  }

  &:focus {
    color: $blanc;
    border: 2px solid $focus;
  }
}

@mixin vnf-btn-primary($color, $hover, $focus) {
  background-color: $color;

  &:hover {
    background-color: $hover;
  }

  &:focus {
    background-color: $focus;
  }
}

.vnf-btn.vnf-btn-multi-color,
.vnf-btn.vnf-btn-contained {
  &.vnf-btn_declinaison-blanche {
    &.vnf-btn-bleu-a1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-a1,
        $vnf-bleu-a1-hover,
        $vnf-bleu-a1-focus
      );
    }

    &.vnf-btn-bleu-a2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-a2,
        $vnf-bleu-a2-hover,
        $vnf-bleu-a2-focus
      );
    }

    &.vnf-btn-bleu-a3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-a3,
        $vnf-bleu-a3-hover,
        $vnf-bleu-a3-focus
      );
    }

    &.vnf-btn-bleu-a4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-a4,
        $vnf-bleu-a4-hover,
        $vnf-bleu-a4-focus
      );
    }

    &.vnf-btn-bleu-a5 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-bleu-a5-hover,
        $vnf-bleu-a5-focus
      );
    }

    &.vnf-btn-bleu-b1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-b1,
        $vnf-bleu-b1-hover,
        $vnf-bleu-b1-focus
      );
    }

    &.vnf-btn-bleu-b2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-b2,
        $vnf-bleu-b2-hover,
        $vnf-bleu-b2-focus
      );
    }

    &.vnf-btn-bleu-b3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-b3,
        $vnf-bleu-b3-hover,
        $vnf-bleu-b3-focus
      );
    }

    &.vnf-btn-bleu-b4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-bleu-b4,
        $vnf-bleu-b4-hover,
        $vnf-bleu-b4-focus
      );
    }

    &.vnf-btn-vert-a1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-a1,
        $vnf-vert-a1-hover,
        $vnf-vert-a1-focus
      );
    }

    &.vnf-btn-vert-a2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-a2,
        $vnf-vert-a2-hover,
        $vnf-vert-a2-focus
      );
    }

    &.vnf-btn-vert-a3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-a3,
        $vnf-vert-a3-hover,
        $vnf-vert-a3-focus
      );
    }

    &.vnf-btn-vert-a4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-a4,
        $vnf-vert-a4-hover,
        $vnf-vert-a4-focus
      );
    }

    &.vnf-btn-vert-b1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-b1,
        $vnf-vert-b1-hover,
        $vnf-vert-b1-focus
      );
    }

    &.vnf-btn-vert-b2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-b2,
        $vnf-vert-b2-hover,
        $vnf-vert-b2-focus
      );
    }

    &.vnf-btn-vert-b3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-b3,
        $vnf-vert-b3-hover,
        $vnf-vert-b3-focus
      );
    }

    &.vnf-btn-vert-b4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-b4,
        $vnf-vert-b4-hover,
        $vnf-vert-b4-focus
      );
    }

    &.vnf-btn-vert-c1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-c1,
        $vnf-vert-c1-hover,
        $vnf-vert-c1-focus
      );
    }

    &.vnf-btn-vert-c2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-c2,
        $vnf-vert-c2-hover,
        $vnf-vert-c2-focus
      );
    }

    &.vnf-btn-vert-c3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-c3,
        $vnf-vert-c3-hover,
        $vnf-vert-c3-focus
      );
    }

    &.vnf-btn-vert-c4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-vert-c4,
        $vnf-vert-c4-hover,
        $vnf-vert-c4-focus
      );
    }

    &.vnf-btn-orange-a1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-orange-a1,
        $vnf-orange-a1-hover,
        $vnf-orange-a1-focus
      );
    }

    &.vnf-btn-orange-a2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-orange-a2,
        $vnf-orange-a2-hover,
        $vnf-orange-a2-focus
      );
    }

    &.vnf-btn-orange-a3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-orange-a3,
        $vnf-orange-a3-hover,
        $vnf-orange-a3-focus
      );
    }

    &.vnf-btn-orange-a4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-orange-a4,
        $vnf-orange-a4-hover,
        $vnf-orange-a4-focus
      );
    }

    &.vnf-btn-violet-a1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-violet-a1,
        $vnf-violet-a1-hover,
        $vnf-violet-a1-focus
      );
    }

    &.vnf-btn-violet-a2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-violet-a2,
        $vnf-violet-a2-hover,
        $vnf-violet-a2-focus
      );
    }

    &.vnf-btn-violet-a3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-violet-a3,
        $vnf-violet-a3-hover,
        $vnf-violet-a3-focus
      );
    }

    &.vnf-btn-violet-a4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-violet-a4,
        $vnf-violet-a4-hover,
        $vnf-violet-a4-focus
      );
    }

    &.vnf-btn-gris-a1 {
      @include vnf-btn_declinaison-blanche(
        $vnf-gris-a1,
        $vnf-gris-a1-hover,
        $vnf-gris-a1-focus
      );
    }

    &.vnf-btn-gris-a2 {
      @include vnf-btn_declinaison-blanche(
        $vnf-gris-a2,
        $vnf-gris-a2-hover,
        $vnf-gris-a2-focus
      );
    }

    &.vnf-btn-gris-a3 {
      @include vnf-btn_declinaison-blanche(
        $vnf-gris-a3,
        $vnf-gris-a3-hover,
        $vnf-gris-a3-focus
      );
    }

    &.vnf-btn-gris-a4 {
      @include vnf-btn_declinaison-blanche(
        $vnf-gris-a4,
        $vnf-gris-a4-hover,
        $vnf-gris-a4-focus
      );
    }

    &:disabled {
      border-color: $vnf-gris-b0;
    }

    &:disabled:hover {
      border-color: $vnf-gris-b0;
    }
  }
}

.vnf-btn.vnf-btn-multi-color,
.vnf-btn.vnf-btn-similar-color,
.vnf-btn.vnf-btn-contained {
  &.vnf-btn-bleu-a1 {
    @include vnf-btn-primary(
      $vnf-bleu-a1,
      $vnf-bleu-a1-hover,
      $vnf-bleu-a1-focus
    );
  }

  &.vnf-btn-bleu-a2 {
    @include vnf-btn-primary(
      $vnf-bleu-a2,
      $vnf-bleu-a2-hover,
      $vnf-bleu-a2-focus
    );
  }

  &.vnf-btn-bleu-a3 {
    @include vnf-btn-primary(
      $vnf-bleu-a3,
      $vnf-bleu-a3-hover,
      $vnf-bleu-a3-focus
    );
  }

  &.vnf-btn-bleu-a4 {
    @include vnf-btn-primary(
      $vnf-bleu-a4,
      $vnf-bleu-a4-hover,
      $vnf-bleu-a4-focus
    );
  }

  &.vnf-btn-bleu-a5 {
    @include vnf-btn-primary(
      $vnf-bleu-a5,
      $vnf-bleu-a5-hover,
      $vnf-bleu-a5-focus
    );
  }

  &.vnf-btn-bleu-b1 {
    @include vnf-btn-primary(
      $vnf-bleu-b1,
      $vnf-bleu-b1-hover,
      $vnf-bleu-b1-focus
    );
  }

  &.vnf-btn-bleu-b2 {
    @include vnf-btn-primary(
      $vnf-bleu-b2,
      $vnf-bleu-b2-hover,
      $vnf-bleu-b2-focus
    );
  }

  &.vnf-btn-bleu-b3 {
    @include vnf-btn-primary(
      $vnf-bleu-b3,
      $vnf-bleu-b3-hover,
      $vnf-bleu-b3-focus
    );
  }

  &.vnf-btn-bleu-b4 {
    @include vnf-btn-primary(
      $vnf-bleu-b4,
      $vnf-bleu-b4-hover,
      $vnf-bleu-b4-focus
    );
  }

  &.vnf-btn-vert-a1 {
    @include vnf-btn-primary(
      $vnf-vert-a1,
      $vnf-vert-a1-hover,
      $vnf-vert-a1-focus
    );
  }

  &.vnf-btn-vert-a2 {
    @include vnf-btn-primary(
      $vnf-vert-a2,
      $vnf-vert-a2-hover,
      $vnf-vert-a2-focus
    );
  }

  &.vnf-btn-vert-a3 {
    @include vnf-btn-primary(
      $vnf-vert-a3,
      $vnf-vert-a3-hover,
      $vnf-vert-a3-focus
    );
  }

  &.vnf-btn-vert-a4 {
    @include vnf-btn-primary(
      $vnf-vert-a4,
      $vnf-vert-a4-hover,
      $vnf-vert-a4-focus
    );
  }

  &.vnf-btn-vert-b1 {
    @include vnf-btn-primary(
      $vnf-vert-b1,
      $vnf-vert-b1-hover,
      $vnf-vert-b1-focus
    );
  }

  &.vnf-btn-vert-b2 {
    @include vnf-btn-primary(
      $vnf-vert-b2,
      $vnf-vert-b2-hover,
      $vnf-vert-b2-focus
    );
  }

  &.vnf-btn-vert-b3 {
    @include vnf-btn-primary(
      $vnf-vert-b3,
      $vnf-vert-b3-hover,
      $vnf-vert-b3-focus
    );
  }

  &.vnf-btn-vert-b4 {
    @include vnf-btn-primary(
      $vnf-vert-b4,
      $vnf-vert-b4-hover,
      $vnf-vert-b4-focus
    );
  }

  &.vnf-btn-vert-c1 {
    @include vnf-btn-primary(
      $vnf-vert-c1,
      $vnf-vert-c1-hover,
      $vnf-vert-c1-focus
    );
  }

  &.vnf-btn-vert-c2 {
    @include vnf-btn-primary(
      $vnf-vert-c2,
      $vnf-vert-c2-hover,
      $vnf-vert-c2-focus
    );
  }

  &.vnf-btn-vert-c3 {
    @include vnf-btn-primary(
      $vnf-vert-c3,
      $vnf-vert-c3-hover,
      $vnf-vert-c3-focus
    );
  }

  &.vnf-btn-vert-c4 {
    @include vnf-btn-primary(
      $vnf-vert-c4,
      $vnf-vert-c4-hover,
      $vnf-vert-c4-focus
    );
  }

  &.vnf-btn-orange-a1 {
    @include vnf-btn-primary(
      $vnf-orange-a1,
      $vnf-orange-a1-hover,
      $vnf-orange-a1-focus
    );
  }

  &.vnf-btn-orange-a2 {
    @include vnf-btn-primary(
      $vnf-orange-a2,
      $vnf-orange-a2-hover,
      $vnf-orange-a2-focus
    );
  }

  &.vnf-btn-orange-a3 {
    @include vnf-btn-primary(
      $vnf-orange-a3,
      $vnf-orange-a3-hover,
      $vnf-orange-a3-focus
    );
  }

  &.vnf-btn-orange-a4 {
    @include vnf-btn-primary(
      $vnf-orange-a4,
      $vnf-orange-a4-hover,
      $vnf-orange-a4-focus
    );
  }

  &.vnf-btn-violet-a1 {
    @include vnf-btn-primary(
      $vnf-violet-a1,
      $vnf-violet-a1-hover,
      $vnf-violet-a1-focus
    );
  }

  &.vnf-btn-violet-a2 {
    @include vnf-btn-primary(
      $vnf-violet-a2,
      $vnf-violet-a2-hover,
      $vnf-violet-a2-focus
    );
  }

  &.vnf-btn-violet-a3 {
    @include vnf-btn-primary(
      $vnf-violet-a3,
      $vnf-violet-a3-hover,
      $vnf-violet-a3-focus
    );
  }

  &.vnf-btn-violet-a4 {
    @include vnf-btn-primary(
      $vnf-violet-a4,
      $vnf-violet-a4-hover,
      $vnf-violet-a4-focus
    );
  }

  &.vnf-btn-gris-a1 {
    @include vnf-btn-primary(
      $vnf-gris-a1,
      $vnf-gris-a1-hover,
      $vnf-gris-a1-focus
    );
  }

  &.vnf-btn-gris-a2 {
    @include vnf-btn-primary(
      $vnf-gris-a2,
      $vnf-gris-a2-hover,
      $vnf-gris-a2-focus
    );
  }

  &.vnf-btn-gris-a3 {
    @include vnf-btn-primary(
      $vnf-gris-a3,
      $vnf-gris-a3-hover,
      $vnf-gris-a3-focus
    );
  }

  &.vnf-btn-gris-a4 {
    @include vnf-btn-primary(
      $vnf-gris-a4,
      $vnf-gris-a4-hover,
      $vnf-gris-a4-focus
    );
  }
}

@mixin vnf-btn-mono-color($color, $hover, $focus) {
  color: $color;

  &.vnf-btn-primary {
    box-shadow: $vnf-btn-box-shadow-offset-x $vnf-btn-box-shadow-offset-y
      $vnf-btn-box-shadow-blur-radius $vnf-btn-box-shadow-spread-radius $color;
  }

  &:hover {
    background-color: $hover;
  }

  &:focus {
    background-color: $focus;
  }
}

.vnf-btn.vnf-btn-mono-color {
  background-color: $blanc;

  &.vnf-btn-bleu-a1 {
    @include vnf-btn-mono-color(
      $vnf-bleu-a1,
      $vnf-bleu-a1-hover,
      $vnf-bleu-a1-focus
    );
  }

  &.vnf-btn-bleu-a2 {
    @include vnf-btn-mono-color(
      $vnf-bleu-a2,
      $vnf-bleu-a2-hover,
      $vnf-bleu-a2-focus
    );
  }

  &.vnf-btn-bleu-a3 {
    @include vnf-btn-mono-color(
      $vnf-bleu-a3,
      $vnf-bleu-a3-hover,
      $vnf-bleu-a3-focus
    );
  }

  &.vnf-btn-bleu-a4 {
    @include vnf-btn-mono-color(
      $vnf-bleu-a4,
      $vnf-bleu-a4-hover,
      $vnf-bleu-a4-focus
    );
  }

  &.vnf-btn-bleu-a5 {
    @include vnf-btn-mono-color(
      $vnf-bleu-a5,
      $vnf-bleu-a5-hover,
      $vnf-bleu-a5-focus
    );
  }

  &.vnf-btn-bleu-b1 {
    @include vnf-btn-mono-color(
      $vnf-bleu-b1,
      $vnf-bleu-b1-hover,
      $vnf-bleu-b1-focus
    );
  }

  &.vnf-btn-bleu-b2 {
    @include vnf-btn-mono-color(
      $vnf-bleu-b2,
      $vnf-bleu-b2-hover,
      $vnf-bleu-b2-focus
    );
  }

  &.vnf-btn-bleu-b3 {
    @include vnf-btn-mono-color(
      $vnf-bleu-b3,
      $vnf-bleu-b3-hover,
      $vnf-bleu-b3-focus
    );
  }

  &.vnf-btn-bleu-b4 {
    @include vnf-btn-mono-color(
      $vnf-bleu-b4,
      $vnf-bleu-b4-hover,
      $vnf-bleu-b4-focus
    );
  }

  &.vnf-btn-vert-a1 {
    @include vnf-btn-mono-color(
      $vnf-vert-a1,
      $vnf-vert-a1-hover,
      $vnf-vert-a1-focus
    );
  }

  &.vnf-btn-vert-a2 {
    @include vnf-btn-mono-color(
      $vnf-vert-a2,
      $vnf-vert-a2-hover,
      $vnf-vert-a2-focus
    );
  }

  &.vnf-btn-vert-a3 {
    @include vnf-btn-mono-color(
      $vnf-vert-a3,
      $vnf-vert-a3-hover,
      $vnf-vert-a3-focus
    );
  }

  &.vnf-btn-vert-a4 {
    @include vnf-btn-mono-color(
      $vnf-vert-a4,
      $vnf-vert-a4-hover,
      $vnf-vert-a4-focus
    );
  }

  &.vnf-btn-vert-b1 {
    @include vnf-btn-mono-color(
      $vnf-vert-b1,
      $vnf-vert-b1-hover,
      $vnf-vert-b1-focus
    );
  }

  &.vnf-btn-vert-b2 {
    @include vnf-btn-mono-color(
      $vnf-vert-b2,
      $vnf-vert-b2-hover,
      $vnf-vert-b2-focus
    );
  }

  &.vnf-btn-vert-b3 {
    @include vnf-btn-mono-color(
      $vnf-vert-b3,
      $vnf-vert-b3-hover,
      $vnf-vert-b3-focus
    );
  }

  &.vnf-btn-vert-b4 {
    @include vnf-btn-mono-color(
      $vnf-vert-b4,
      $vnf-vert-b4-hover,
      $vnf-vert-b4-focus
    );
  }

  &.vnf-btn-vert-c1 {
    @include vnf-btn-mono-color(
      $vnf-vert-c1,
      $vnf-vert-c1-hover,
      $vnf-vert-c1-focus
    );
  }

  &.vnf-btn-vert-c2 {
    @include vnf-btn-mono-color(
      $vnf-vert-c2,
      $vnf-vert-c2-hover,
      $vnf-vert-c2-focus
    );
  }

  &.vnf-btn-vert-c3 {
    @include vnf-btn-mono-color(
      $vnf-vert-c3,
      $vnf-vert-c3-hover,
      $vnf-vert-c3-focus
    );
  }

  &.vnf-btn-vert-c4 {
    @include vnf-btn-mono-color(
      $vnf-vert-c4,
      $vnf-vert-c4-hover,
      $vnf-vert-c4-focus
    );
  }

  &.vnf-btn-orange-a1 {
    @include vnf-btn-mono-color(
      $vnf-orange-a1,
      $vnf-orange-a1-hover,
      $vnf-orange-a1-focus
    );
  }

  &.vnf-btn-orange-a2 {
    @include vnf-btn-mono-color(
      $vnf-orange-a2,
      $vnf-orange-a2-hover,
      $vnf-orange-a2-focus
    );
  }

  &.vnf-btn-orange-a3 {
    @include vnf-btn-mono-color(
      $vnf-orange-a3,
      $vnf-orange-a3-hover,
      $vnf-orange-a3-focus
    );
  }

  &.vnf-btn-orange-a4 {
    @include vnf-btn-mono-color(
      $vnf-orange-a4,
      $vnf-orange-a4-hover,
      $vnf-orange-a4-focus
    );
  }

  &.vnf-btn-violet-a1 {
    @include vnf-btn-mono-color(
      $vnf-violet-a1,
      $vnf-violet-a1-hover,
      $vnf-violet-a1-focus
    );
  }

  &.vnf-btn-violet-a2 {
    @include vnf-btn-mono-color(
      $vnf-violet-a2,
      $vnf-violet-a2-hover,
      $vnf-violet-a2-focus
    );
  }

  &.vnf-btn-violet-a3 {
    @include vnf-btn-mono-color(
      $vnf-violet-a3,
      $vnf-violet-a3-hover,
      $vnf-violet-a3-focus
    );
  }

  &.vnf-btn-violet-a4 {
    @include vnf-btn-mono-color(
      $vnf-violet-a4,
      $vnf-violet-a4-hover,
      $vnf-violet-a4-focus
    );
  }

  &.vnf-btn-gris-a1 {
    @include vnf-btn-mono-color(
      $vnf-gris-a1,
      $vnf-gris-a1-hover,
      $vnf-gris-a1-focus
    );
  }

  &.vnf-btn-gris-a2 {
    @include vnf-btn-mono-color(
      $vnf-gris-a2,
      $vnf-gris-a2-hover,
      $vnf-gris-a2-focus
    );
  }

  &.vnf-btn-gris-a3 {
    @include vnf-btn-mono-color(
      $vnf-gris-a3,
      $vnf-gris-a3-hover,
      $vnf-gris-a3-focus
    );
  }

  &.vnf-btn-gris-a4 {
    @include vnf-btn-mono-color(
      $vnf-gris-a4,
      $vnf-gris-a4-hover,
      $vnf-gris-a4-focus
    );
  }

  &:hover,
  &:focus {
    color: $blanc;
  }
}

$vnf-btn-border-style: solid;
$vnf-btn-border-width: 2px;

@mixin vnf-btn-outline($color, $hover, $focus) {
  color: $color;
  border-color: $color;

  &:hover {
    border-color: $hover;
    background-color: $hover;
  }

  &:focus {
    border-color: $focus;
    background-color: $focus;
  }
}

@mixin vnf-btn-outline_declinaison-blanche($color, $hover, $focus) {
  color: $color;
  border-color: $color;

  &:hover {
    border-color: $hover;
    background-color: $hover;
  }

  &:focus {
    border-color: $focus;
    background-color: $focus;
  }
}

.vnf-btn.vnf-btn-outline {
  border-style: $vnf-btn-border-style;
  border-width: $vnf-btn-border-width;
  background-color: $blanc;

  &.vnf-btn-outline-icon-label {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;

    i {
      font-size: 40px;
    }

    &.hidden {
      visibility: hidden;
    }
  }

  &.vnf-btn-outline_declinaison-blanche {
    background-color: transparent;

    &.vnf-btn-bleu-a1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-bleu-a1-hover,
        $vnf-bleu-a1-focus
      );
    }

    &.vnf-btn-bleu-a2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-a2-hover,
        $vnf-bleu-a2-focus
      );
    }

    &.vnf-btn-bleu-a3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-a3-hover,
        $vnf-bleu-a3-focus
      );
    }

    &.vnf-btn-bleu-a4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-a4-hover,
        $vnf-bleu-a4-focus
      );
    }

    &.vnf-btn-bleu-a5 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-a5-hover,
        $vnf-bleu-a5-focus
      );
    }

    &.vnf-btn-bleu-b1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-bleu-b1-hover,
        $vnf-bleu-b1-focus
      );
    }

    &.vnf-btn-bleu-b2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-b2-hover,
        $vnf-bleu-b2-focus
      );
    }

    &.vnf-btn-bleu-b3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-b3-hover,
        $vnf-bleu-b3-focus
      );
    }

    &.vnf-btn-bleu-b4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-bleu-b4-hover,
        $vnf-bleu-b4-focus
      );
    }

    &.vnf-btn-vert-a1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-vert-a1-hover,
        $vnf-vert-a1-focus
      );
    }

    &.vnf-btn-vert-a2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-a2-hover,
        $vnf-vert-a2-focus
      );
    }

    &.vnf-btn-vert-a3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-a3-hover,
        $vnf-vert-a3-focus
      );
    }

    &.vnf-btn-vert-a4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-a4-hover,
        $vnf-vert-a4-focus
      );
    }

    &.vnf-btn-vert-b1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-vert-b1-hover,
        $vnf-vert-b1-focus
      );
    }

    &.vnf-btn-vert-b2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-b2-hover,
        $vnf-vert-b2-focus
      );
    }

    &.vnf-btn-vert-b3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-b3-hover,
        $vnf-vert-b3-focus
      );
    }

    &.vnf-btn-vert-b4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-b4-hover,
        $vnf-vert-b4-focus
      );
    }

    &.vnf-btn-vert-c1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-vert-c1-hover,
        $vnf-vert-c1-focus
      );
    }

    &.vnf-btn-vert-c2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-c2-hover,
        $vnf-vert-c2-focus
      );
    }

    &.vnf-btn-vert-c3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-c3-hover,
        $vnf-vert-c3-focus
      );
    }

    &.vnf-btn-vert-c4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-vert-c4-hover,
        $vnf-vert-c4-focus
      );
    }

    &.vnf-btn-orange-a1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-orange-a1-hover,
        $vnf-orange-a1-focus
      );
    }

    &.vnf-btn-orange-a2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-orange-a2-hover,
        $vnf-orange-a2-focus
      );
    }

    &.vnf-btn-orange-a3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-orange-a3-hover,
        $vnf-orange-a3-focus
      );
    }

    &.vnf-btn-orange-a4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-orange-a4-hover,
        $vnf-orange-a4-focus
      );
    }

    &.vnf-btn-violet-a1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-violet-a1-hover,
        $vnf-violet-a1-focus
      );
    }

    &.vnf-btn-violet-a2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-violet-a2-hover,
        $vnf-violet-a2-focus
      );
    }

    &.vnf-btn-violet-a3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-violet-a3-hover,
        $vnf-violet-a3-focus
      );
    }

    &.vnf-btn-violet-a4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-violet-a4-hover,
        $vnf-violet-a4-focus
      );
    }

    &.vnf-btn-gris-a1 {
      @include vnf-btn-outline_declinaison-blanche(
        $vnf-bleu-a5,
        $vnf-gris-a1-hover,
        $vnf-gris-a1-focus
      );
    }

    &.vnf-btn-gris-a2 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-gris-a2-hover,
        $vnf-gris-a2-focus
      );
    }

    &.vnf-btn-gris-a3 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-gris-a3-hover,
        $vnf-gris-a3-focus
      );
    }

    &.vnf-btn-gris-a4 {
      @include vnf-btn-outline_declinaison-blanche(
        $blanc,
        $vnf-gris-a4-hover,
        $vnf-gris-a4-focus
      );
    }

    &:disabled,
    &:disabled:hover {
      border-color: $vnf-gris-b2;
      background-color: transparent !important;
    }
  }

  &.vnf-btn-blanc {
    @include vnf-btn-outline($blanc, $blanc, $blanc);
  }

  &.vnf-btn-bleu-a1 {
    @include vnf-btn-outline(
      $vnf-bleu-a1,
      $vnf-bleu-a1-hover,
      $vnf-bleu-a1-focus
    );
  }

  &.vnf-btn-bleu-a2 {
    @include vnf-btn-outline(
      $vnf-bleu-a2,
      $vnf-bleu-a2-hover,
      $vnf-bleu-a2-focus
    );
  }

  &.vnf-btn-bleu-a3 {
    @include vnf-btn-outline(
      $vnf-bleu-a3,
      $vnf-bleu-a3-hover,
      $vnf-bleu-a3-focus
    );
  }

  &.vnf-btn-bleu-a4 {
    @include vnf-btn-outline(
      $vnf-bleu-a4,
      $vnf-bleu-a4-hover,
      $vnf-bleu-a4-focus
    );
  }

  &.vnf-btn-bleu-a5 {
    @include vnf-btn-outline(
      $vnf-bleu-a5,
      $vnf-bleu-a5-hover,
      $vnf-bleu-a5-focus
    );
  }

  &.vnf-btn-bleu-b1 {
    @include vnf-btn-outline(
      $vnf-bleu-b1,
      $vnf-bleu-b1-hover,
      $vnf-bleu-b1-focus
    );
  }

  &.vnf-btn-bleu-b2 {
    @include vnf-btn-outline(
      $vnf-bleu-b2,
      $vnf-bleu-b2-hover,
      $vnf-bleu-b2-focus
    );
  }

  &.vnf-btn-bleu-b3 {
    @include vnf-btn-outline(
      $vnf-bleu-b3,
      $vnf-bleu-b3-hover,
      $vnf-bleu-b3-focus
    );
  }

  &.vnf-btn-bleu-b4 {
    @include vnf-btn-outline(
      $vnf-bleu-b4,
      $vnf-bleu-b4-hover,
      $vnf-bleu-b4-focus
    );
  }

  &.vnf-btn-vert-a1 {
    @include vnf-btn-outline(
      $vnf-vert-a1,
      $vnf-vert-a1-hover,
      $vnf-vert-a1-focus
    );
  }

  &.vnf-btn-vert-a2 {
    @include vnf-btn-outline(
      $vnf-vert-a2,
      $vnf-vert-a2-hover,
      $vnf-vert-a2-focus
    );
  }

  &.vnf-btn-vert-a3 {
    @include vnf-btn-outline(
      $vnf-vert-a3,
      $vnf-vert-a3-hover,
      $vnf-vert-a3-focus
    );
  }

  &.vnf-btn-vert-a4 {
    @include vnf-btn-outline(
      $vnf-vert-a4,
      $vnf-vert-a4-hover,
      $vnf-vert-a4-focus
    );
  }

  &.vnf-btn-vert-b1 {
    @include vnf-btn-outline(
      $vnf-vert-b1,
      $vnf-vert-b1-hover,
      $vnf-vert-b1-focus
    );
  }

  &.vnf-btn-vert-b2 {
    @include vnf-btn-outline(
      $vnf-vert-b2,
      $vnf-vert-b2-hover,
      $vnf-vert-b2-focus
    );
  }

  &.vnf-btn-vert-b3 {
    @include vnf-btn-outline(
      $vnf-vert-b3,
      $vnf-vert-b3-hover,
      $vnf-vert-b3-focus
    );
  }

  &.vnf-btn-vert-b4 {
    @include vnf-btn-outline(
      $vnf-vert-b4,
      $vnf-vert-b4-hover,
      $vnf-vert-b4-focus
    );
  }

  &.vnf-btn-vert-c1 {
    @include vnf-btn-outline(
      $vnf-vert-c1,
      $vnf-vert-c1-hover,
      $vnf-vert-c1-focus
    );
  }

  &.vnf-btn-vert-c2 {
    @include vnf-btn-outline(
      $vnf-vert-c2,
      $vnf-vert-c2-hover,
      $vnf-vert-c2-focus
    );
  }

  &.vnf-btn-vert-c3 {
    @include vnf-btn-outline(
      $vnf-vert-c3,
      $vnf-vert-c3-hover,
      $vnf-vert-c3-focus
    );
  }

  &.vnf-btn-vert-c4 {
    @include vnf-btn-outline(
      $vnf-vert-c4,
      $vnf-vert-c4-hover,
      $vnf-vert-c4-focus
    );
  }

  &.vnf-btn-orange-a1 {
    @include vnf-btn-outline(
      $vnf-orange-a1,
      $vnf-orange-a1-hover,
      $vnf-orange-a1-focus
    );
  }

  &.vnf-btn-orange-a2 {
    @include vnf-btn-outline(
      $vnf-orange-a2,
      $vnf-orange-a2-hover,
      $vnf-orange-a2-focus
    );
  }

  &.vnf-btn-orange-a3 {
    @include vnf-btn-outline(
      $vnf-orange-a3,
      $vnf-orange-a3-hover,
      $vnf-orange-a3-focus
    );
  }

  &.vnf-btn-orange-a4 {
    @include vnf-btn-outline(
      $vnf-orange-a4,
      $vnf-orange-a4-hover,
      $vnf-orange-a4-focus
    );
  }

  &.vnf-btn-violet-a1 {
    @include vnf-btn-outline(
      $vnf-violet-a1,
      $vnf-violet-a1-hover,
      $vnf-violet-a1-focus
    );
  }

  &.vnf-btn-violet-a2 {
    @include vnf-btn-outline(
      $vnf-violet-a2,
      $vnf-violet-a2-hover,
      $vnf-violet-a2-focus
    );
  }

  &.vnf-btn-violet-a3 {
    @include vnf-btn-outline(
      $vnf-violet-a3,
      $vnf-violet-a3-hover,
      $vnf-violet-a3-focus
    );
  }

  &.vnf-btn-violet-a4 {
    @include vnf-btn-outline(
      $vnf-violet-a4,
      $vnf-violet-a4-hover,
      $vnf-violet-a4-focus
    );
  }

  &.vnf-btn-gris-a1 {
    @include vnf-btn-outline(
      $vnf-gris-a1,
      $vnf-gris-a1-hover,
      $vnf-gris-a1-focus
    );
  }

  &.vnf-btn-gris-a2 {
    @include vnf-btn-outline(
      $vnf-gris-a2,
      $vnf-gris-a2-hover,
      $vnf-gris-a2-focus
    );
  }

  &.vnf-btn-gris-a3 {
    @include vnf-btn-outline(
      $vnf-gris-a3,
      $vnf-gris-a3-hover,
      $vnf-gris-a3-focus
    );
  }

  &.vnf-btn-gris-a4 {
    @include vnf-btn-outline(
      $vnf-gris-a4,
      $vnf-gris-a4-hover,
      $vnf-gris-a4-focus
    );
  }

  &:hover,
  &:focus {
    color: $blanc;
  }

  &:disabled,
  &:disabled:hover {
    border-color: $vnf-gris-b0;
  }
}

.vnf-btn:disabled,
.vnf-btn:disabled:hover {
  color: $vnf-gris-b2 !important;
  background-color: $vnf-gris-b0 !important;
  cursor: not-allowed;

  &.vnf-btn-primary {
    box-shadow: 4px 4px 0 $vnf-gris-b2 !important;
  }
}

.vnf-btn.vnf-icon-before {
  padding-left: 10px;

  i {
    margin-right: 5px;
    vertical-align: middle;
  }
}

@mixin vnf-btn-tertiary($color, $hover, $focus) {
  color: $color;
  border-color: $color;

  &:hover {
    border-color: $hover;
    background-color: $hover;
    color: $blanc;
  }

  &:focus {
    border-color: $focus;
    background-color: $focus;
    padding: 4.4px 15px;
    color: $blanc;
  }

  &::after {
    background-color: $color;
  }
}

.vnf-btn.vnf-btn-tertiary {
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  display: flex;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-style: solid;
  border-width: 2px;
  background-color: $blanc;

  &:disabled {
    border-color: $vnf-gris-b2 !important;
    color: $vnf-gris-b2 !important;
    background-color: $vnf-gris-b0 !important;
    cursor: not-allowed;
  }
}

.vnf-btn-tertiary.vnf-btn-m {
  font-size: 16px;
  font-weight: 400;
  padding: 4.4px 7.5px 4.4px 15px;
  letter-spacing: 1px;
}

.vnf-btn-tertiary {
  &:not(:focus) {
    &::after {
      content: "";
      margin-left: 7.5px;
      width: 15px;
      height: 15px;
      -moz-border-radius: 7.5px;
      -webkit-border-radius: 7.5px;
      border-radius: 7.5px;
    }
  }
}

.vnf-btn.vnf-btn-tertiary {
  &.vnf-btn-bleu-a1 {
    @include vnf-btn-tertiary(
      $vnf-bleu-a1,
      $vnf-bleu-a1-hover,
      $vnf-bleu-a1-focus
    );
  }

  &.vnf-btn-bleu-a2 {
    @include vnf-btn-tertiary(
      $vnf-bleu-a2,
      $vnf-bleu-a2-hover,
      $vnf-bleu-a2-focus
    );
  }

  &.vnf-btn-bleu-a3 {
    @include vnf-btn-tertiary(
      $vnf-bleu-a3,
      $vnf-bleu-a3-hover,
      $vnf-bleu-a3-focus
    );
  }

  &.vnf-btn-bleu-a4 {
    @include vnf-btn-tertiary(
      $vnf-bleu-a4,
      $vnf-bleu-a4-hover,
      $vnf-bleu-a4-focus
    );
  }

  &.vnf-btn-bleu-a5 {
    @include vnf-btn-tertiary(
      $vnf-bleu-a5,
      $vnf-bleu-a5-hover,
      $vnf-bleu-a5-focus
    );
  }

  &.vnf-btn-bleu-b1 {
    @include vnf-btn-tertiary(
      $vnf-bleu-b1,
      $vnf-bleu-b1-hover,
      $vnf-bleu-b1-focus
    );
  }

  &.vnf-btn-bleu-b2 {
    @include vnf-btn-tertiary(
      $vnf-bleu-b2,
      $vnf-bleu-b2-hover,
      $vnf-bleu-b2-focus
    );
  }

  &.vnf-btn-bleu-b3 {
    @include vnf-btn-tertiary(
      $vnf-bleu-b3,
      $vnf-bleu-b3-hover,
      $vnf-bleu-b3-focus
    );
  }

  &.vnf-btn-bleu-b4 {
    @include vnf-btn-tertiary(
      $vnf-bleu-b4,
      $vnf-bleu-b4-hover,
      $vnf-bleu-b4-focus
    );
  }

  &.vnf-btn-vert-a1 {
    @include vnf-btn-tertiary(
      $vnf-vert-a1,
      $vnf-vert-a1-hover,
      $vnf-vert-a1-focus
    );
  }

  &.vnf-btn-vert-a2 {
    @include vnf-btn-tertiary(
      $vnf-vert-a2,
      $vnf-vert-a2-hover,
      $vnf-vert-a2-focus
    );
  }

  &.vnf-btn-vert-a3 {
    @include vnf-btn-tertiary(
      $vnf-vert-a3,
      $vnf-vert-a3-hover,
      $vnf-vert-a3-focus
    );
  }

  &.vnf-btn-vert-a4 {
    @include vnf-btn-tertiary(
      $vnf-vert-a4,
      $vnf-vert-a4-hover,
      $vnf-vert-a4-focus
    );
  }

  &.vnf-btn-vert-b1 {
    @include vnf-btn-tertiary(
      $vnf-vert-b1,
      $vnf-vert-b1-hover,
      $vnf-vert-b1-focus
    );
  }

  &.vnf-btn-vert-b2 {
    @include vnf-btn-tertiary(
      $vnf-vert-b2,
      $vnf-vert-b2-hover,
      $vnf-vert-b2-focus
    );
  }

  &.vnf-btn-vert-b3 {
    @include vnf-btn-tertiary(
      $vnf-vert-b3,
      $vnf-vert-b3-hover,
      $vnf-vert-b3-focus
    );
  }

  &.vnf-btn-vert-b4 {
    @include vnf-btn-tertiary(
      $vnf-vert-b4,
      $vnf-vert-b4-hover,
      $vnf-vert-b4-focus
    );
  }

  &.vnf-btn-vert-c1 {
    @include vnf-btn-tertiary(
      $vnf-vert-c1,
      $vnf-vert-c1-hover,
      $vnf-vert-c1-focus
    );
  }

  &.vnf-btn-vert-c2 {
    @include vnf-btn-tertiary(
      $vnf-vert-c2,
      $vnf-vert-c2-hover,
      $vnf-vert-c2-focus
    );
  }

  &.vnf-btn-vert-c3 {
    @include vnf-btn-tertiary(
      $vnf-vert-c3,
      $vnf-vert-c3-hover,
      $vnf-vert-c3-focus
    );
  }

  &.vnf-btn-vert-c4 {
    @include vnf-btn-tertiary(
      $vnf-vert-c4,
      $vnf-vert-c4-hover,
      $vnf-vert-c4-focus
    );
  }

  &.vnf-btn-orange-a1 {
    @include vnf-btn-tertiary(
      $vnf-orange-a1,
      $vnf-orange-a1-hover,
      $vnf-orange-a1-focus
    );
  }

  &.vnf-btn-orange-a2 {
    @include vnf-btn-tertiary(
      $vnf-orange-a2,
      $vnf-orange-a2-hover,
      $vnf-orange-a2-focus
    );
  }

  &.vnf-btn-orange-a3 {
    @include vnf-btn-tertiary(
      $vnf-orange-a3,
      $vnf-orange-a3-hover,
      $vnf-orange-a3-focus
    );
  }

  &.vnf-btn-orange-a4 {
    @include vnf-btn-tertiary(
      $vnf-orange-a4,
      $vnf-orange-a4-hover,
      $vnf-orange-a4-focus
    );
  }

  &.vnf-btn-violet-a1 {
    @include vnf-btn-tertiary(
      $vnf-violet-a1,
      $vnf-violet-a1-hover,
      $vnf-violet-a1-focus
    );
  }

  &.vnf-btn-violet-a2 {
    @include vnf-btn-tertiary(
      $vnf-violet-a2,
      $vnf-violet-a2-hover,
      $vnf-violet-a2-focus
    );
  }

  &.vnf-btn-violet-a3 {
    @include vnf-btn-tertiary(
      $vnf-violet-a3,
      $vnf-violet-a3-hover,
      $vnf-violet-a3-focus
    );
  }

  &.vnf-btn-violet-a4 {
    @include vnf-btn-tertiary(
      $vnf-violet-a4,
      $vnf-violet-a4-hover,
      $vnf-violet-a4-focus
    );
  }

  &.vnf-btn-gris-a1 {
    @include vnf-btn-tertiary(
      $vnf-gris-a1,
      $vnf-gris-a1-hover,
      $vnf-gris-a1-focus
    );
  }

  &.vnf-btn-gris-a2 {
    @include vnf-btn-tertiary(
      $vnf-gris-a2,
      $vnf-gris-a2-hover,
      $vnf-gris-a2-focus
    );
  }

  &.vnf-btn-gris-a3 {
    @include vnf-btn-tertiary(
      $vnf-gris-a3,
      $vnf-gris-a3-hover,
      $vnf-gris-a3-focus
    );
  }

  &.vnf-btn-gris-a4 {
    @include vnf-btn-tertiary(
      $vnf-gris-a4,
      $vnf-gris-a4-hover,
      $vnf-gris-a4-focus
    );
  }

  &:hover,
  &:focus {
    color: $blanc;
  }

  &:disabled,
  &:disabled:hover {
    border-color: $vnf-gris-b0;
  }
}
