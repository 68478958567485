.section {
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 992px) {
        margin-left: unset;
        margin-right: unset;
    }

    &__header {
        padding: 20px 30px 45px;
        display: flex;

        @media (min-width: 992px) {
            padding: 35px 50px 105px;

        }
    }

    &__title {
        font-size: 30px;
        font-family: 'Roboto';
        font-weight: 300;
        color: inherit;
        display: flex;
        flex-direction: column;
        margin: 0;

        &:after {
            font-family: 'vnf-icons-v2' !important;
            content: "\e90c";
            font-size: 31px;
        }

        @media (min-width: 992px) {
            font-size: 44px;
        }
    }

    &__container {
        margin-top: -30px !important;

        @media (min-width: 992px) {
            margin-top: -90px !important;
            padding: 0 50px !important;
        }
    }

    &--green {
        .section__header {
            color: $white;
            background: #3CA941;
        }

        .section__title {
            &:after {
                color: #FBD81E;
            }
        }
    }

    &--blue {
        .section__header {
            color: $white;
            background: #5D91FE;
        }

        .section__title {
            &:after {
                color: #86EC8B;
            }
        }
    }
}

.section-card {
    background: $white;
    margin-bottom: 30px;

    @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__container {
        padding: 15px;

        @media (min-width: 992px) {
            padding: 20px 30px;
        }
    }

    &__title {
        font-size: 30px;
        font-family: 'Roboto';
        font-weight: 300;
        color: $vnf-bleu-a4;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 60px;
        margin: 0;

        &:after {
            font-family: 'vnf-icons-v2' !important;
            content: "\e90c";
        }

        &--green {
            &:after {
                color: #3CA941;
            }
        }

        &--blue {
            &:after {
                color: #5D91FE;
            }
        }
    }

    .primary-form {
        padding: 0;
        margin: 0 auto;
    }
}

.section-shortcuts {
    padding: 20px;
    background: $vnf-bleu-b4;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        font-family: 'Roboto';
        font-size: 30px;
        font-weight: 300;
        text-align: center;
        color: $white;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        &:after {
            font-family: 'vnf-icons-v2' !important;
            content: "\e90c";
            color: #fbd81e;
        }
    }

    &__link {
        padding: 16px 32px;
        background: $white;
        color: $vnf-bleu-a4;
        font-weight: 300;
        font-size: 18px;
        text-decoration: none;
        margin-bottom: 35px;
        box-shadow: 4px 4px 0 0 $vnf-bleu-a1;
        width: 250px;
        max-width: 100%;
        text-align: center;
        transition: $transition-bezier;
        outline: none;

        &:hover,
        &:focus {
            box-shadow: 4px 4px 0 0 $vnf-orange-a1;
        }
    }

    @media (max-width: 767px) {
        display: none;
    }
}