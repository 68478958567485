.reactour {
    &__popover {
        font-family: 'Robot', sans-serif;
        padding: 50px 30px 20px!important;

        // Badge
        & > span {
            height: auto;
            padding: 10px 15px;
            line-height: 1;
            background: $vnf-bleu-a2;
            font-weight: 700;
            border-radius: 0;
            left: 15px;
            top: 0;
            transform: translateY(-50%);
        }

        // Close button
        & > button {
            top: 20px;
            right: 20px;
            width: 12px;
            height: 12px;
        }

        @media (max-width: 373px) {
            max-width: calc(100% - 40px) !important;
        }
    }
}