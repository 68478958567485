/** Colors **/
$white: #fff;
$black: #000;

$vnf-dt1: #127646;
$vnf-dt2: #0085cc;
$vnf-dt3: #1ab9de;
$vnf-dt4: #51ad32;
$vnf-dt5: #6758a1;
$vnf-dt6: #ed7203;
$vnf-dt7: #de1953;
$vnf-dt8: #79142e;

$blue100: #a6b1c7;
$blue200: #04cbc5;
$blue300: #5e93ff;
$blue400: #346bdc;
$blue500: #446bbb;
$blue600: #2554b5;
$blue700: #184297;
$blue800: #143477;
$blue900: #132a59;

$green400: #86ec8b;
$green500: #71db76;
$green600: #a2c517;
$green700: #59a537;
$green800: #266b07;

$orange600: #ff7300;
$orange700: #eb6b03;
$orange800: #d34400;

$red600: #f00;

$gray400: #f6f6f6;
$gray600: #eee;
$gray500: #e8e8e8;
$gray700: #d8d8d8;
$gray800: #d4d4d4;
$gray900: #6e6e6e;
$gray1000: #323232;

$blanc: #fff;
$vnf-bleu-a1: #8fbcff;
$vnf-bleu-a1-focus: #5380e1;
$vnf-bleu-a1-hover: #77adff;
$vnf-bleu-a2: #5e92ff;
$vnf-bleu-a2-focus: #436cc6;
$vnf-bleu-a2-hover: #5380e1;
$vnf-bleu-a3: #436cc6;
$vnf-bleu-a3-focus: #30549e;
$vnf-bleu-a3-hover: #3c64b5;
$vnf-bleu-a4: #3155a4;
$vnf-bleu-a4-focus: #1d408c;
$vnf-bleu-a4-hover: #294b98;
$vnf-bleu-a5: #143477;
$vnf-bleu-a5-focus: #001c57;
$vnf-bleu-a5-hover: #09235b;
$vnf-bleu-b1: #7ecaee;
$vnf-bleu-b1-focus: #3ea1cf;
$vnf-bleu-b1-hover: #5fb5de;
$vnf-bleu-b2: #66b6dc;
$vnf-bleu-b2-focus: #3590bc;
$vnf-bleu-b2-hover: #55a6cd;
$vnf-bleu-b3: #3a9ccb;
$vnf-bleu-b3-focus: #1a7dab;
$vnf-bleu-b3-hover: #1f8dc1;
$vnf-bleu-b4: #1978a6;
$vnf-bleu-b4-focus: #015882;
$vnf-bleu-b4-hover: #0c6996;
$vnf-disabled: #f6f6f6;
$vnf-gris-a1: #a5c8ec;
$vnf-gris-a1-focus: #789dc1;
$vnf-gris-a1-hover: #92b2d2;
$vnf-gris-a2: #83a7cb;
$vnf-gris-a2-focus: #5a80a6;
$vnf-gris-a2-hover: #6f95bb;
$vnf-gris-a3: #6f8eac;
$vnf-gris-a3-focus: #466a8d;
$vnf-gris-a3-hover: #5a7ea0;
$vnf-gris-a4: #5e748a;
$vnf-gris-a4-focus: #36526f;
$vnf-gris-a4-hover: #476685;
$vnf-gris-b0: #f9f9f9;
$vnf-gris-b1: #eee;
$vnf-gris-b2: #c2c2c2;
$vnf-gris-b3: #767676;
$vnf-gris-b4: #4e4e4e;
$vnf-gris-b5: #000;
$vnf-notif-error: #f00;
$vnf-notif-success: #4cba51;
$vnf-notif-warning: #fb8700;
$vnf-orange-a1: #fbd81e;
$vnf-orange-a1-focus: #ffa902;
$vnf-orange-a1-hover: #ffc700;
$vnf-orange-a2: #ffb800;
$vnf-orange-a2-focus: #fe7325;
$vnf-orange-a2-hover: #ff8f0c;
$vnf-orange-a3: #f90;
$vnf-orange-a3-focus: #eb6900;
$vnf-orange-a3-hover: #ff7300;
$vnf-orange-a4: #ff7300;
$vnf-orange-a4-focus: #df5507;
$vnf-orange-a4-hover: #f55e09;
$vnf-vert-a1: #0dddd7;
$vnf-vert-a1-focus: #05b4ae;
$vnf-vert-a1-hover: #00c0bb;
$vnf-vert-a2: #04cbc5;
$vnf-vert-a2-focus: #009c97;
$vnf-vert-a2-hover: #00b4ae;
$vnf-vert-a3: #1aaaa6;
$vnf-vert-a3-focus: #13807c;
$vnf-vert-a3-hover: #179995;
$vnf-vert-a4: #217f6e;
$vnf-vert-a4-focus: #176456;
$vnf-vert-a4-hover: #167160;
$vnf-vert-b1: #86ec8b;
$vnf-vert-b1-focus: #62ce68;
$vnf-vert-b1-hover: #71dd77;
$vnf-vert-b2: #5ecb63;
$vnf-vert-b2-focus: #3ea743;
$vnf-vert-b2-hover: #4cba51;
$vnf-vert-b3: #65b144;
$vnf-vert-b3-focus: #4a882f;
$vnf-vert-b3-hover: #5a9e3c;
$vnf-vert-b4: #237f21;
$vnf-vert-b4-focus: #0d510b;
$vnf-vert-b4-hover: #1d671b;
$vnf-vert-c1: #c6e544;
$vnf-vert-c1-focus: #a2c60c;
$vnf-vert-c1-hover: #aed216;
$vnf-vert-c2: #b2cf3b;
$vnf-vert-c2-focus: #93ad28;
$vnf-vert-c2-hover: #9fbb2d;
$vnf-vert-c3: #a1b842;
$vnf-vert-c3-focus: #778c1e;
$vnf-vert-c3-hover: #89a41d;
$vnf-vert-c4: #89a02a;
$vnf-vert-c4-focus: #607701;
$vnf-vert-c4-hover: #768e12;
$vnf-violet-a1: #9174ff;
$vnf-violet-a1-focus: #6142dd;
$vnf-violet-a1-hover: #7656f4;
$vnf-violet-a2: #6b44ff;
$vnf-violet-a2-focus: #3e19ce;
$vnf-violet-a2-hover: #5933e9;
$vnf-violet-a3: #4f2bd8;
$vnf-violet-a3-focus: #270e89;
$vnf-violet-a3-hover: #3c18c3;
$vnf-violet-a4: #3716b6;
$vnf-violet-a4-focus: #19085b;
$vnf-violet-a4-hover: #22068e;

$primary-color: $blue400;
$secondary-color: $orange600;
$link-hover: $orange600;

$network-twitter: #00adea;
$network-facebook: #3c5998;
$network-instagram: #86ec8b;
$network-links: #bababa;

$alert-success: #4cba51;
$alert-danger: #ff0000;
$alert-warning: #FB8700;
$alert-info: #436cc6;

// TRANSITION
$transition-bezier: 150ms cubic-bezier(0.4, 0, 0.2, 1);
$transition-linear: 0.25s linear;

// OMBRE
$shadow-sm: rgba(0, 0, 0, 0.25) 1px 1px 4px;

// Terminals and Connectors
$connector-size-lg: 402px;
$connector-size-md: 225px;

// Breakpoints
$screen-md-min: 768px;