.__react_component_tooltip {
    &.tooltip {
        border-radius: 0;
        border-width: 2px;
        font-family: 'Roboto';
        font-size: 18px;
        padding: 4px 10px !important;
        box-shadow: $shadow-sm;
        z-index: 4000;

        &.show {
            opacity: 1;
        }
    }
}
