body .tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  &--blue {
    --tabColor: #fff;
    --tabBackground: #436cc6;
    --tabIndicator: #86ec8b;
  }

  &--green {
    --tabColor: #fff;
    --tabBackground: #237f21;
    --tabIndicator: #fbd81e;
  }

  &__list {
    color: var(--tabColor);
    background: var(--tabBackground);
  }

  &__item {
    border: 1px dotted var(--tabBackground);
    opacity: 1;
    margin: 0;
    min-height: 50px;

    &:focus-visible {
      border-color: var(--tabColor);
    }
  }

  .MuiTabs-indicator {
    background-color: var(--tabIndicator);
    height: 4px;
  }

  &__panel {
    display: none;
    height: 100%;

    &.open {
      display: block;
    }
  }
}
