.tickets {
    &__info {
        display: table;
        width: 100%;

        li {
            display: table-row;
        }

        span {
            display: table-cell;
            padding-bottom: 4px;

            &:first-child {
                width: 1%;
                padding-right: 10px;
            }
        }

        margin-bottom: 10px;
    }

    &__comment-list {
        margin-top: 20px;
        padding: 15px 0;
        border-top: 1px solid $vnf-gris-b1;
        border-bottom: 1px solid $vnf-gris-b1;
    }

    &__comment {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__author {
        margin: 0;
        font-size: 16px;
        text-transform: uppercase;
        flex: 1;
    }

    &__date {
        font-size: 12px;
    }

    &__message {
        font-size: 14px;
        width: 100%;
        margin: 0;
        padding: 0 10px;
        border-left: 5px solid $vnf-gris-b1;
    }

}