.cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  border-radius: 0;
  border: 1px solid #E6E9ED;
  padding: 0.5rem 1rem;
  background-color: white;
  cursor: move;
  width: 45%;
  height: 300px;
}

h2.chartTitle {
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 20%;
}

.chart {
  height: 80%;
}

.loader_overlay {
  position: fixed!important;
}

.phone-input {
  width: inherit;
  display: inline-block;
}

.a {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.pro-sidebar {
  position: fixed;
}

.pro-sidebar .pro-menu.square .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  border-radius: 25px;
}

.MuiTooltip-popper {
  // Fix z-index issue with MuiTooltip
  z-index: 3000!important;
}