.register {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('/assets/img/login_background.svg') no-repeat center;
    background-size: cover;

    &__wrapper {
        width: 770px;
        max-width: 100%;
        background: $white;
        margin: 20px auto;

    }

    &__logo {
        text-align: center;

        img {
            width: 120px;

            @media (min-width: 768px) {
                width: 180px;
            }
        }
    }

    &__title {
        font-weight: 400;
        text-align: center;
    }

    &__form-wrapper {
        padding: 30px 15px;

        @media (min-width: 768px) {
            padding: 50px 30px;
            box-shadow: $shadow-sm;
        }
    }

    &__form {
        width: 100%;
        background: transparent !important;
        padding: 0;
    }

    &__backlink {
        display: inline-block;
        margin-top: 30px;
        color: $vnf-bleu-a4;

        &:hover,
        &:focus {
            color: $vnf-orange-a4;
        }
    }

    &__personal-data {
        display: flex;
        align-items: center;
        background: $vnf-bleu-a4;
        color: #fff;
        padding: 10px;
        font-size: 14px;
        width: 100%;
        justify-content: center;
        border: none;
        cursor: pointer;

        &:hover {
            background-color: $vnf-bleu-a4-hover;
        }

        &:focus {
            outline: none;
            background-color: $vnf-bleu-a4-focus;
        }

        span {
            font-size: 24px;
            margin-right: 6px;
        }
    }
}

.steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    gap: 10px;
    margin: 20px auto;

    li {
        flex: 1;
        position: relative;

        // &:after {
        //     content: "";
        //     height: 2px;
        //     width: 100%;
        //     position: absolute;
        //     top: 13px;
        //     left: 0;
        //     background: red;
        //     z-index: 2;
        // }

        // &:first-child:after {
        //     left: 50%;
        //     z-index: 1;
        // }

        // &:last-child:after {
        //     left: -50%;
        //     z-index: 2;
        // }
    }
    
    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        text-transform: uppercase;
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 12px;
        color: $vnf-bleu-a4;
        font-weight: 700;
    }

    &__number {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        font-size: 18px;
        margin-bottom: 6px;
        line-height: 1;

        &--active {
            background: url('/assets/img/icons/step-current.svg') no-repeat center;
            background-size: contain;
            color: $white;
        }
        
        &--inactive {
            background: url('/assets/img/icons/step-pending.svg') no-repeat center;
        }
        
        &--done {
            text-indent: -9999px;
            background: url('/assets/img/icons/step-done.svg') no-repeat center;
        }
    }
}