.terminal-recap {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: -10px;
  font-family: "Roboto", sans-serif;

  &__col {
    position: relative;

    &:not(:last-child):after {
      content: "";
      background: $white;
      opacity: 0.3;
      display: block;

      @media (max-width: 991px) {
        width: 85px;
        max-width: 100%;
        height: 2px;
        margin: 30px auto;
      }

      @media (min-width: 992px) {
        position: absolute;
        width: 2px;
        height: 100%;
        top: 0;
        right: 0;
        transform: translateX(50%);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
  }

  &__value {
    font-size: 30px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 6px;
    }

    &--success:before {
      background-color: #3ca941;
    }

    &--warning:before {
      background-color: #fb8700;
    }

    &--error:before {
      background-color: #ff0000;
    }
  }

  &__label {
    font-size: 10px;
    font-weight: 400;
    text-align: center;
  }
}

.terminal-map {
  width: 100%;
  height: 480px;
  margin-top: 60px;
  margin-bottom: -60px;
  position: relative;
  z-index: 1;

  @media (max-width: $screen-md-min) {
    visibility: hidden;
    position: fixed;
    transform: translateX(-1000%);
  }

  &__legend {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $white;
    z-index: 1001;
    list-style: none;
    display: flex;

    li {
      display: flex;
      align-items: center;
      color: $black;
      text-transform: uppercase;
      font-family: "Roboto Condensed", sans-serif;
      font-size: 11px;
      margin-right: 10px;

      img {
        width: 34px;
      }
    }
  }

  .leaflet-marker.focused {
    img {
      &:not(:hover) {
        transform-origin: bottom;
        animation: markerAnimation 2s ease-out infinite;
      }
    }

    &:after {
      content: "";
      width: 34px;
      height: 34px;
      position: absolute;
      z-index: -1;
      top: 75%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-shadow: inset 0 0 10px 2px #fff;
      animation: markerPulse 2s linear infinite;
    }
  }

  .leaflet-control-zoom {
    margin-top: 200px;
    margin-left: 0;
    border: none;

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      border: none;
      box-shadow: $shadow-sm;
      background: $white;
      width: 40px;
      height: 40px;
      text-indent: -9999px;
      overflow: hidden;
      background-color: $white;

      &:hover,
      &:focus {
        background-color: $vnf-gris-b1;
      }
    }

    .leaflet-control-zoom-in {
      border-radius: 0 10px 0 0 !important;
      background-image: url("/assets/img/icons/icon-m-zoom-outline.svg");
    }

    .leaflet-control-zoom-out {
      border-radius: 0 0 10px 0 !important;
      background-image: url("/assets/img/icons/icon-m-dezoom-outline.svg");
    }
  }
}

.connector-list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  margin-top: 30px;

  @for $i from 1 through 10 {
    &__col-#{$i} {
      @media (min-width: $screen-md-min) {
        grid-template-columns: repeat(#{$i}, 1fr);
      }
    }

    &__row-#{$i} {
      @media (min-width: $screen-md-min) {
        grid-template-rows: repeat(#{$i}, 1fr);
      }
    }
  }

  @media (max-width: $screen-md-min) {
    grid-template-rows: unset !important;
  }

  &__header {
    background: $vnf-bleu-a4;
    color: $white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 18px 14px;
    position: relative;

    @media (max-width: $screen-md-min) {
      padding-right: 54px;
    }
  }

  &__toggle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    appearance: none;
    background: transparent;
    color: $white;
    font-size: 40px;
    cursor: pointer;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }

  &__title {
    font-size: 24px;
    margin: 0;
    color: inherit;
    font-weight: 300;

    &__id {
      color: lightgray;
    }
  }

  &__type {
    margin-left: 6px;
    font-weight: 700;
  }

  &__connector-id {
    font-size: medium;
    color: lightgray;
  }

  &__item {
    // Minus 4px to avoid overflow with 3 columns
    min-width: $connector-size-lg - 4px;

    @media (max-width: $screen-md-min) {
      min-width: $connector-size-md;
    }

    &__empty {
      background: #9aa9d0;

      @media (max-width: $screen-md-min) {
        display: none;
      }
    }
  }

  &__icon {
    @media (min-width: $screen-md-min) {
      width: auto;
      margin-left: auto;
    }
  }

  &__status {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 1;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin-right: 7px;
    }

    &--disponible:before {
      background-color: #3ca941;
    }

    &--occupe:before {
      background-color: #fb8700;
    }

    &--indisponible:before {
      background-color: #ff0000;
    }

    @media (min-width: $screen-md-min) {
      width: auto;
    }
  }

  &__body {
    @media (min-width: $screen-md-min) {
      display: flex;
    }
  }

  &__left,
  &__right {
    padding: 18px 14px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 78px;
    color: $white;

    .input-wrapper {
      width: auto;
      
      .checkbox {
        // Center the checkbox with Start - Stop toggle
        padding-top: 6px;
      }
    }
  }

  &__left {
    background: $vnf-bleu-a3;
  }

  &__right {
    background: #264487;
  }

  &__slidedown {
    @media (min-width: $screen-md-min) {
      display: block !important;
      height: unset !important;
    }
  }
}

.terminal-tooltip {
  &__top {
    display: flex;
  }

  &__status {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    top: 3px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "vnf-icons-v2" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &--Available,
    &--AvailableFull {
      background: #3ca941;
    }

    &--Unavailable,
    &--UnavailableOccupiedFull {
      background: #ff0000;
    }

    &--Occupied {
      background: #fb8700;
    }

    &--OccupiedFull {
      background: #fb8700;
      color: #fff;

      &:after {
        content: "\e908";
      }
    }

    &--UnavailableFull {
      background: #ff0000;
      color: #fff;

      &:after {
        content: "\e908";
      }
    }

    &--UnavailableOccupied {
      background: #fb8700;
      color: #ff0000;

      &:after {
        content: "\e908";
      }
    }

    &--Offline {
      background-color: $vnf-gris-b1;
    }
  }
}

.terminal-details {
  width: 660px;
  max-width: 100%;
  padding: 20px 30px;
  margin: auto;
  color: $white;
  margin: 30px auto;

  &--bottom {
    background: $vnf-bleu-a4;
  }

  &--top {
    background: $vnf-bleu-a3;
  }

  &__title {
    color: inherit;
    margin: 0 0 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 24px;
  }

  &__transactions {
    display: flex;
    justify-content: space-around;

    li {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      font-size: 30px;
      font-weight: 300;

      span {
        margin-top: 4px;
        font-weight: 400;
        font-size: 10px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    li {
      font-size: 16px;
    }
  }
}

.terminal__please-wait {
  text-align: center;
  margin: 35px 0;
}

@keyframes markerPulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) scale(2.3);
    opacity: 0;
  }
}

@keyframes markerAnimation {
  0% {
    transform: scale(1, 1) translateY(0);
  }

  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }

  30% {
    transform: scale(0.9, 1.1) translateY(-2px);
  }

  50% {
    transform: scale(1, 1) translateY(0);
  }

  100% {
    transform: scale(1, 1) translateY(0);
  }
}