.main-title {
    text-align: center;
    margin-bottom: 30px;
}

.container {
    padding: 0 15px;
    width: 1377px;
    max-width: 100%;
    margin: 0 auto;
}

#main-content {
    padding: 30px 0;
    min-height: calc(100% - 261px);

    @media (min-width: 768px) {
        padding-top: 90px;
        min-height: calc(100% - 333px);
    }
}

.homepage-logo {
    text-align: center;
    margin-bottom: 30px;

    img {
        width: 115px;

        @media (min-width: 768px) {
            width: 180px;
        }
    }
}

.skipLink {
    position: fixed;
    top: 60px;
    left: 0;
    padding: 10px;
    background: $vnf-bleu-a4-hover;
    color: $white;
    text-decoration: none;
    transform: translateX(-100%);

    &:focus {
        transform: none;
    }
}