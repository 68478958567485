.header {
    // background-color: $vnf-bleu-a3;
    background-color: #12709D;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    nav {
        background: inherit;
        z-index: inherit;

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;

            a,
            button {
                appearance: none;
                border: none;
                background: transparent;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin: 0;
                color: $white;
                cursor: pointer;
                text-decoration: none;

                i {
                    font-size: 40px;
                    opacity: 0.65;
                    transition: $transition-bezier;
                }

                span {
                    font-family: 'Roboto';
                    font-size: 10px;
                    line-height: 1;
                    position: relative;
                    top: -4px;
                }

                &:hover,
                &:focus,
                &.active {
                    i {
                        opacity: 1;
                    }
                }

                .vnf-icons-icon-m-menu-b-solid {
                    position: relative;
                    top: 2px;
                }
            }
        }
    }

    &__logo {
        width: 60px;
        height: 60px;
        margin-left: auto;
        background: $white;
        padding: 2px;
    }

    @media (max-width: 767px) {
        nav {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;

            ul {
                justify-content: space-around;
            }
        }
    }

    @media (min-width: 768px) {
        position: fixed;

    }
}

.header-shortcuts {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    &__header {
        height: 60px;
        background-color: #12709D;
        display: flex;
        justify-content: flex-end;
    }

    &__body {
        background: $vnf-bleu-b4;
        flex: 1;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    &__title {
        font-family: 'Roboto';
        font-size: 25px;
        font-weight: 300;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        &:after {
            font-family: 'vnf-icons-v2' !important;
            content: "\e90c";
            color: $vnf-orange-a1;
        }
    }

    &__link {
        padding: 16px 32px;
        background: $white;
        color: $vnf-bleu-a4;
        font-weight: 300;
        font-size: 18px;
        text-decoration: none;
        margin-bottom: 35px;
        box-shadow: 4px 4px 0 0 $vnf-bleu-a1;
        width: 250px;
        max-width: 100%;
        text-align: center;
        transition: $transition-bezier;
        outline: none;

        &:hover,
        &:focus {
            box-shadow: 4px 4px 0 0 $vnf-orange-a1;
        }
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.main-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 430px;
    max-width: 100%;
    z-index: 1000;
    height: 100%;
    transform: translateX(-100%);
    transition: $transition-linear;
    background: $vnf-bleu-a3;

    &.open {
        transform: none;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $vnf-gris-b4;
        z-index: 800;
        opacity: 0;
        transition: $transition-linear;
        pointer-events: none;

        &.open {
            opacity: 0.5;
            pointer-events: unset;
        }
    }

    &__menu {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &--level-0 {
            background: $vnf-bleu-a3;
            color: $white;
            left: 0;
        }

        &--level-1 {
            background: $white;
            color: $vnf-bleu-a4;
            transform: translateX(-100%);
            transition: $transition-linear;

            &.open {
                transform: none;
            }
        }

        &--level-2 {
            background: $vnf-gris-b1;
            color: $vnf-bleu-a4;
            transform: translateX(-100%);
            transition: $transition-linear;
            
            &.open {
                transform: none;
            }
        }

        @media (min-width: 1290px) {
            z-index: -1;

            &--level-1,
            &--level-2 {
                left: 100%;
            }
        }
    }

    &__menu-wrapper {
        width: 100%;
        height: 100%;
        background: inherit;
        overflow: hidden auto;
    }

    &__top {
       position: relative;
    }

    &__close {
        appearance: none;
        background: transparent;
        border: none;
        color: $white;
        font-size: 30px;
        cursor: pointer;
        margin: 30px 30px 20px;
    }

    &__goback {
        appearance: none;
        background: transparent;
        border: none;
        color: inherit;
        font-size: 30px;
        cursor: pointer;
        margin: 30px 0 20px 30px;
    }

    &__list {
        list-style: none;
    }

    &__separator {
        height: 1px;
        background: $white;
        transform: translateY(-50%);
        margin: 15px 30px;
        opacity: 0.4;

        @media (min-width: 992px) {
            margin: 15px 35px;
        }

        &--nopadding {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__link {
        font-family: 'Roboto';
        display: flex;
        justify-content: space-between;
        appearance: none;
        width: 100%;
        border: none;
        background: transparent;
        color: inherit;
        text-decoration: none;
        text-align: left;
        font-size: 18px;
        padding: 10px 30px;
        font-weight: 300;
        cursor: pointer;
        position: relative;

        &--has-sublink {
            i {
                position: relative;
                top: 4px;
            }
        }

        &.active {
            font-weight: 500;
        }

        &:hover,
        &:focus {
            background: $vnf-vert-b1;
            color: $vnf-bleu-a4;

            &:after,
            &:before {
                opacity: 0;
            }
        }

        @media (min-width: 992px) {
            font-size: 24px;
            padding: 15px 35px;
        }
    }

    &__menu-title {
        font-family: 'Roboto';
        width: 100%;
        color: inherit;
        font-size: 18px;
        padding: 10px 30px;
        font-weight: 500;
        margin: 0;

        @media (min-width: 992px) {
            font-size: 24px;
            padding: 15px 35px;
        }
    }

    &__quicklinks {
        padding: 30px;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;

        @media (min-width: 992px) {
            padding: 30px 35px;
        }

        a {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: none;
            color: $white;
            padding: 10px;
            border: 1px solid $white;
            transition: $transition-bezier;
            font-size: 15px;

            &:hover,
            &:focus,
            &.active {
                background: $white;
                color: $vnf-bleu-a4;
            }
        }
    }

    &__footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 30px 30px;

        @media (min-width: 992px) {
            padding: 0 35px 35px;
        }

        p {
            text-align: center;
            width: 100%;
            margin-bottom: 15px;
        }
    }
}
