.login {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('/assets/img/login_background.svg') no-repeat center;
    background-size: cover;

    &__wrapper {
        width: 368px;
        max-width: 100%;
        background: $white;
        margin: 20px 0;
    }

    &__logo {
        text-align: center;

        p {
            display: none;
            color: #636363;
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
            max-width: 200px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__partners {
        background-color: $vnf-bleu-a3;
        padding: 20px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        p {
            width: 100%;
            text-align: center;
            font-size: 15px;
            margin-top: 0;
            margin-bottom: 10px;
            color: $white;
        }
    }
}