.language-selector {
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: 768px) {
        top: 20px;
        left: 20px;
    }

    &__toggle {
        appearance: none;
        font-size: 16px;
        font-size: 'Roboto';
        color: $white;
        background: $vnf-bleu-a3;
        padding: 6px 10px;
        cursor: pointer;
        display: flex;
        align-items: center;

        i {
            font-size: 24px;
            line-height: 0;
            position: relative;
            top: 1px;
        }
    }

    &__content {
        transition: $transition-bezier;
        overflow: hidden;

        ul {
            list-style: none;
    
            button {
                font-size: 'Roboto';
                appearance: none;
                width: 100%;
                padding: 6px 10px;
                text-align: unset;
                color: $white;
                font-size: 16px;
                background: $vnf-bleu-a5;
                cursor: pointer;
                outline: none;
                border: none;
    
                &:hover {
                    background: $vnf-bleu-a4-hover;
                }
    
                &:focus {
                    background: $vnf-bleu-a4-focus;
                }
            }
        }
    }
}

.language-selector--navigation {
    &__toggle {
        position: absolute;
        right: 14px;
        top: 20px;
        appearance: none;
        font-size: 16px;
        font-size: 'Roboto';
        color: $white;
        background: transparent;
        padding: 16px 30px;
        cursor: pointer;
        display: flex;
        align-items: center;

        i {
            font-size: 24px;
            line-height: 0;
            position: relative;
            top: 1px;
        }

        @media (min-width: 992px) {
            padding: 20px;
        }
    }

    &__content {
        transition: $transition-bezier;
        overflow: hidden;

        ul {
            list-style: none;
    
            button {
                font-size: 'Roboto';
                appearance: none;
                width: 100%;
                padding: 16px 30px;
                text-align: unset;
                color: $white;
                font-size: 16px;
                background: $vnf-bleu-a4;
                cursor: pointer;
                outline: none;
                border: none;
    
                &:first-child {
                    padding-top: 16px;
                }
    
                &:last-child {
                    padding-bottom: 16px;
                }
    
                @media (min-width: 992px) {
                    padding: 10px 35px;
                }
    
                &:hover {
                    background: $vnf-bleu-a4-hover;
                }
    
                &:focus {
                    background: $vnf-bleu-a4-focus;
                }
            }
        }
    }
}
