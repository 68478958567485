.charts {
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        padding: 20px;
        display: flex;
        gap: 20px;

        input,
        select {
            color: $vnf-bleu-a4;
            border-radius: 4px;
            border-color: $vnf-gris-b2;
            font-size: 13px;
            font-family: 'Roboto', sans-serif;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 10px;
        // padding-right: 36px;
    
        // .icon-button {
        //     position: absolute;
        //     right: 0;
        //     top: 17px;
        // }

        .modal__title {
            margin: 0;
        }
    }

    &__footer {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }

    &__container {
        height: 490px;

        &--homepage {
            height: 320px;

            @media (min-width: 992px) {
                margin-bottom: -40px;
            }
        }
    
        svg {
            text {
                font-family: 'Roboto', sans-serif !important;
                font-size: 11px !important;
                fill: $vnf-gris-b3 !important;
            }
        }
    }

    &__tooltip {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        color: $vnf-bleu-a2;
        background: $white;
        border: 2px solid $vnf-bleu-a2;
        padding: 4px 10px;
        box-shadow: $shadow-sm;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        &:before,
        &:after {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
        }
    
        &:before {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            bottom: -8px;
            left: 50%;
            margin-left: -10px;
            border-top: 8px solid $vnf-bleu-a2;
        }
    
        &:after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            bottom: -6px;
            left: 50%;
            margin-left: -8px;
            border-top-color: $white;
            border-top-style: solid;
            border-top-width: 6px;
        }

        &-top {
            display: flex;
            align-items: center;
        }

        &-badge {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 4px;
        }
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        font-family: "Roboto Condensed", sans-serif;
        font-size: 13px;
        text-align: left;
    
        &--green {
            background: $vnf-vert-b4;
        }
    
        &--blue {
            background: $vnf-bleu-a3;
        }
    
        &__container {
            height: calc(100% - 36px);
            overflow: auto;
        }
    
        th,
        td {
          padding: 10px;
          vertical-align: middle;
    
          &:first-child {
            padding-left: 20px;
          }
    
          &:last-child {
            padding-right: 20px;
          }
        }
        
        th {
          height: 50px;
        }
    
        thead {
            background: inherit;
            color: white;
            position: sticky;
            top: 0;
            width: 100%;
      
            tr {
              border-bottom: 4px solid #eee;
            }
        }
    
        tbody {
            background: white;
            color: black;
            overflow-y: auto;
      
            tr {
              border-bottom: 2px solid #eee;
      
              &:hover,
              &:focus-within {
                background: #f9f9f9;
              }
            }
        }
    }

    &--fullscreen {
        position: fixed;
        top: 60px;
        height: calc(100% - 60px);
        left: 0;
        z-index: 2000;

        .charts__container {
            flex: 1;

            &--homepage {
                margin-bottom: 0;
            }
        }
    }
}
