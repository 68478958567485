.table-header {
  font-family: "Roboto Condensed", sans-serif;
  padding: 20px 25px;
  height: 100%;
  position: relative;

  &__container {
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;

    @media (min-width: 768px) {
      overflow: unset;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 30px;

      &--with-action {
        margin-bottom: 0;
      }
    }
  }

  &--blue {
    background: $vnf-bleu-a3;
    color: $white;
  }

  &--green {
    background: #3CA941;
    color: $white;
  }

  &__title {
    font-size: 30px;
    font-weight: 300;
    color: inherit;
    margin-top: 0;
    margin-bottom: 20px;

    &--center {
      text-align: center;
    }
  }

  &__actions {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 768px) {
      padding: 25px 0;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__link {
    color: $vnf-bleu-a4;
    font-size: 16px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    position: relative;
    left: -6px;
    appearance: none;
    background: transparent;
    cursor: pointer;
    padding: 0;

    i {
      font-size: 32px;
    }

    &:hover {
      color: $vnf-bleu-a4-hover;
    }

    &:focus {
      color: $vnf-bleu-a4-focus;
    }
  }
}

.card-table {
  width: 100%;
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 13px;
  // margin-bottom: 70px;

  &__wrapper {
    margin-bottom: 30px;
  }

  &--green {
    background: #3ca941;
  }

  &--blue {
    background: $vnf-bleu-a3;
  }

  &__pending {
    font-family: "Roboto Condensed", sans-serif;
    display: flex;
    align-items: center;
    color: #3A6EDB;
    font-size: 13px;
    line-height: 1;
    white-space: nowrap;

    i {
      font-size: 22px;
    }
  }

  &__status {
    text-transform: uppercase;
    white-space: nowrap;
    position: relative;
    padding-left: 30px !important;
    width: 1%;
    max-width: 200px;
    // width: 200px;
    // background: red;

    &:before {
      content: "";
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-family: 'vnf-icons-v2' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &--top {
      &:before {
        position: static;
        transform: none;
      }
    }

    &--disponible:before,
    &--Available:before,
    &--AvailableFull:before {
      background: #3ca941;
    }

    &--indisponible:before,
    &--Unavailable:before,
    &--UnavailableOccupiedFull:before {
      background: #ff0000;
    }

    &--occupe:before,
    &--Occupied:before {
      background: #fb8700;
    }

    &--OccupiedFull:before {
      background: #fb8700;
      color: #fff;
      content: "\e908";
    }

    &--UnavailableFull:before {
      background: #ff0000;
      color: #fff;
      content: "\e908";
    }

    &--UnavailableOccupied::before {
      background: #fb8700;
      color: #ff0000;
      content: "\e908";
    }

    &--Offline::before {
      background-color: $vnf-gris-b1;
    }
  }

  &__actions-wrapper {
    display: flex;
    align-items: center;
  }

  &__action {
    text-decoration: none;
    color: $vnf-bleu-a3;
    font-size: 36px;
    line-height: 1;
    display: inline;
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
      color: $vnf-bleu-a3-hover;
    }

    &:focus {
      color: $vnf-bleu-a3-focus;
    }
  }

  // DESKTOP -> TABLE Design
  @media (min-width: 768px) {
    border-collapse: collapse;
    text-align: left;
    width: 1377px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    th,
    td {
      padding: 10px;
      vertical-align: middle;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
    
    th {
      height: 50px;
    }

    thead {
      background: inherit;
      color: white;

      tr {
        border-bottom: 4px solid #eee;
      }
    }

    tbody {
      background: white;
      color: black;

      tr {
        &:not(:last-child) {
          border-bottom: 2px solid #eee;
        }

        &:hover,
        &:focus-within,
        &.focused {
          background: #f9f9f9;
        }
      }
    }

    &__min-width {
        width: 1%;
    }
  }

  // MOBILE -> Card Design
  @media (max-width: 767px) {
    &__wrapper {
      margin-left: -15px;
      margin-right: -15px;
    }

    thead {
      position: fixed;
      left: -1000%;
      top: 0;
    }

    tbody {
      display: block;

      tr {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        padding: 20px;
        margin: 20px 8px;
        gap: 10px;
        position: relative;

        td {
          width: calc(50% - 5px);
          min-width: 120px;
          word-break: break-all;

          &:before {
            content: attr(data-label);
            color: #888;
            display: block;
            font-size: 10px;
          }
        }

        .card-table {
          &__show {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            i {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;

              &:before {
                content: "";
              }
            }
          }

          &__status {
            order: 1;
            direction: rtl;
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            width: auto;
            min-width: unset;

            &--top {
              order: -1;
              direction: ltr;
              width: 100%;
              max-width: unset;
              padding-left: 0 !important;

              &:before {
                position: static;
              }
            }
          }

          &__actions {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-left: auto;
          }

          &__d-none {
            display: none;
          }

          &__w-100 {
            width: 100%;
          }
        }
      }
    }
  }
}

.badge {
  font-weight: 700;
  padding: 3px 6px 2px;
  display: inline-block;
  margin: 2px;
  background: $vnf-bleu-a2;
  color: $white;
  border-radius: 4px;

  &--ROLE_ADMIN {
    background: $vnf-bleu-a4;
  }

  &--ROLE_SUPER_ADMIN {
    background: $vnf-bleu-a5;
  }
}
