.react-toast-notifications__container {
    padding: 15px !important;
    z-index: 999999 !important;
}

.vnf-toast {
    background: $white;
    box-shadow: $shadow-sm;
    color: $vnf-bleu-a5;
    border-left: 9px solid transparent;
    position: relative;
    min-height: 50px;
    width: 315px;
    max-width: 100%;
    margin-bottom: 10px;
    animation: toastIn ease-in-out .25s forwards;
    will-change: opacity;
    padding: 15px 0;
    z-index: 999999;

    &.close {
        animation: toastOut ease-in-out .25s forwards;
    }

    &__header {
        display: flex;
        padding: 0 50px 0 15px;
    }

    &__content {
        padding: 0 20px;
    }

    &__icon {
        margin-right: 10px;
        margin-top: -4px;
        font-size: 28px;
    }

    &__title {
        font-size: 14px;
        margin: 0;

        &--md {
            font-size: 16px;
        }

        &--xl {
            font-size: 20px;
        }
    }
    
    &__date {
        font-size: 12px;
        color: $vnf-gris-b3;
        margin-bottom: 4px;
        font-style: italic;
    }

    &__message {
        font-size: 13px;
        color: $vnf-bleu-a5;
        margin: 0;
    }

    &__close {
        appearance: none;
        width: 50px;
        height: 50px;
        background: $white;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $vnf-gris-b2;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $vnf-gris-b3;
        }

        i {
            font-size: 28px;
        }
    }

    &__progress {
        position: absolute;
        width: 40px;
        animation-duration: 2s;

        .CircularProgressbar {
            animation-duration: inherit;

            .CircularProgressbar-path {
                stroke: #d2d2d2;
                animation-name: toastProgress;
                animation-timing-function: linear;
                animation-fill-mode: forwards;
                animation-duration: inherit;
            }

            .CircularProgressbar-trail {
                stroke: $white;
            }
        }
    }

    &--danger {
        border-left-color: $alert-danger;
        color: $alert-danger;
    }

    &--info {
        border-left-color: $alert-info;
        color: $alert-info;
    }

    &--warning {
        border-left-color: $alert-warning;
        color: $alert-warning;
    }

    &--success {
        border-left-color: $alert-success;
        color: $alert-success;
    }

    &--xl {
        width: 660px;

        .vnf-toast__icon {
            margin-top: 2px;
        }
    }
}

@keyframes toastIn {
    from {
        transform: translateX(200%);
    }
    to {
        transform: none;
    }
}

@keyframes toastOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes toastProgress {
    0% {
        stroke-dashoffset: 0px;
        opacity: 1;
    }
    50% {
        stroke-dashoffset: 142px;
        opacity: 1;
    }
    to {
        stroke-dashoffset: 284px;
        opacity: .2;
    }
}