.footer {
  @media (max-width: 767px) {
    padding-bottom: 60px;
  }

  &__top {
    padding: 25px;
    background: #e9e9e9;

    p {
      padding-left: 20px;
      color: #636363;
      font-size: 13px;
      margin: 0;
    }

    img {
        width: 150px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__bottom {
    padding: 25px 40px;
    background: #dddddd;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    p {
      width: 100%;
      color: #636363;
      font-size: 13px;
      display: none;
      text-align: center;
    }

    @media (max-width: 767px) {
        padding: 25px 10px;
        justify-content: center;

      p {
        display: block;

      }
    }
  }

  &__replay-tour {
    padding: 15px;
    background: #dddddd;
    text-align: right;

    button {
      appearance: none;
      background: transparent;
      font-family: inherit;
      color: #636363;
      font-size: 13px;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__img-separator {
    width: 1px;
    height: 24px;
    background: $vnf-bleu-b4;
    margin: 0 40px;

    @media (max-width: 767px) {
      margin: 0 30px;
    }
  }
}
